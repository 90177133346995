<template>
  <div>
    <img
      class="place-image my-5"
      src="https://omnimodaStorage.b-cdn.net/icons8-tick.gif"
    />
    <h4 class="my-5">Thank you! Payment was successful</h4>

    ${{ (resp.amount_total / 100).toFixed(2) }} credits have been added to your
    account.
    <div class="my-3">
      <router-link to="/panel/account"
        >Click here to go to the workspace</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: "stipeSuccess",
  data() {
    return { resp: "" };
  },
  methods: {
    getSession() {
      let url = process.env.VUE_APP_BASE_API_URL + "stripe/getPaymentDetails";

      this.axios.get(url, { withCredentials: true }).then(response => {
        if (response.data.error) {
          this.$router.push({ name: "account" });
        }

        this.resp = response.data;
      });
    }
  },
  mounted() {
    this.getSession();
  }
};
</script>

<style scoped>
.place-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 80px;
}
</style>
