<template>
  <div class="my-5">
    <div class="mx-auto mt-5">
      <div class="m-2" style="max-width: 1200px">
        <h3 class="color-grey-800">Manage my plan and credits</h3>
        Change plan - View invoice history
        <div
          v-if="this.planValue"
          class="my-3 d-flex justify-content-center color-grey-700"
        >
          <div class="pricing-card bg-blue-grey-50 text-left p-2 m-4">
            <div class="bg-blue-grey-900 rounded color-grey-200 p-2 my-1">
              <div class="mx-auto text-center">CURRENT PLAN</div>
            </div>
            <div class="font-weight-bold mt-1 ms-2 color-blue-grey-700">
              {{ getcurrentPlanDetails()[0].name }}
            </div>
            <div class="font-weight-light mt-1 ms-2" style="font-size:small">
              {{ getcurrentPlanDetails()[0].button1 }}
            </div>
            <div
              class="ms-2 mt-4 mb-2 color-blue-grey-900"
              style="font-size:xxx-large; font-weight:600;"
            >
              <div
                class="ms-2 mt-4 mb-2 color-blue-grey-900"
                style="font-size:xxx-large; font-weight:600;"
              >
                ${{
                  isMonthlyCurrentPlan
                    ? getcurrentPlanDetails()[0].price.monthly
                    : getcurrentPlanDetails()[0].price.annual
                }}
                <span
                  class="ms-2 mt-4 mb-2 color-blue-grey-700"
                  style="font-size: large;  vertical-align: super;"
                >
                  {{ isMonthlyCurrentPlan ? "Month" : "Year" }}
                </span>
              </div>
              <div>
                <div
                  class="bg-blue-grey-900 color-grey-50 getstarted-button"
                  style="font-size:medium"
                  :class="{ wobble: switchplan }"
                >
                  <div @click="() => (this.switchplan = !switchplan)">
                    <div v-show="this.switchplan == false">Change plan</div>
                    <div v-show="this.switchplan == true">
                      Click me to hide plans
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="!this.planValue || this.switchplan == true">
          <h5>Choose a plan below.</h5>
        </div>
        <!-- special starting plan -->
        <div
          v-if="!this.planValue"
          class="my-3 d-flex justify-content-center color-grey-700"
        >
          <div class="pricing-card bg-blue-grey-50 text-left p-2 m-4">
            <div class="bg-blue-900 rounded color-grey-200 p-2 my-1">
              <div class="mx-auto text-center">Special Offer</div>
            </div>
            <div class="font-weight-bold mt-1 ms-2 color-blue-grey-700">
              {{ prices[0].name }}
            </div>
            <div class="font-weight-light mt-1 ms-2" style="font-size:small">
              {{ prices[0].button1 }}
            </div>
            <div
              class="ms-2 mt-4 mb-2 color-blue-grey-900"
              style="font-size:xxx-large; font-weight:600;"
            >
              <div
                class="ms-2 mt-4 mb-2 color-blue-grey-900"
                style="font-size:xxx-large; font-weight:600;"
              >
                ${{ prices[0].price.monthly }}
                <span
                  class="ms-2 mt-4 mb-2 color-blue-grey-700"
                  style="font-size: large;  vertical-align: super;"
                >
                  Month
                </span>
              </div>
              <div>
                <div
                  class="bg-blue-grey-900 color-grey-50 getstarted-button"
                  style="font-size:medium"
                >
                  <div @click="getStripeSession(prices[0].pricecode.monthly)">
                    Subscribe to plan
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end special plan -->
        <div v-show="!this.planValue || this.switchplan == true">
          <ToggleButton
            v-model="isMonthly"
            :labels="{ checked: 'Monthly', unchecked: 'Annual' }"
            :width="140"
            :height="40"
            :font-size="18"
          />
          <div style="height: 20px">
            <div
              v-show="!isMonthly"
              class="mx-auto my-3 color-green-a400 heartbeat"
            >
              Get 2 months free
            </div>
          </div>

          <div class="d-md-flex justify-content-center">
            <template v-for="price in prices">
              <div :key="price.name" v-show="price.show">
                <div class="pricing-card bg-blue-grey-50 text-left p-2 m-4">
                  <div class="font-weight-bold mt-1 ms-2 color-blue-grey-800">
                    {{ price.name }}
                  </div>
                  <div
                    class="font-weight-light mt-1 ms-2"
                    style="font-size:small"
                  >
                    {{ price.button1 }}
                  </div>
                  <div
                    class="ms-2 mt-4 mb-2 color-blue-grey-900"
                    style="font-size:xxx-large; font-weight:600;"
                  >
                    ${{ isMonthly ? price.price.monthly : price.price.annual }}
                    <span
                      class="ms-2 mt-4 mb-2 color-blue-grey-700"
                      style="font-size: large;  vertical-align: super;"
                    >
                      {{ isMonthly ? "Month" : "Year" }}
                    </span>
                  </div>

                  <div>
                    <div
                      class="bg-blue-grey-900 color-grey-50 getstarted-button"
                    >
                      <div
                        @click="
                          getStripeSession(
                            isMonthly
                              ? price.pricecode.monthly
                              : price.pricecode.annual
                          )
                        "
                      >
                        <div v-show="switchplan == true">
                          Change to this plan
                        </div>
                        <div v-show="switchplan == false">
                          Subscribe to plan
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <ul
                    class="pricing-info color-blue-900 mx-auto px-0"
                    style="font-size:smaller;"
                  >
                    <li class="my-2">
                      Realtime SMS conversations<i
                        class="fa-solid fa-circle-check mt-1"
                        style="color:rgb(11, 30, 106); text-align:left;"
                      ></i>
                    </li>
                    <li class="my-2">
                      Mobile number validation<i
                        class="fa-solid fa-circle-check mt-1 "
                        style="color:rgb(11, 30, 106);text-align:left;"
                      ></i>
                    </li>
                    <li class="my-2">
                      Automate you SMS sending<i
                        class="fa-solid fa-circle-check mt-1"
                        style="color:rgb(11, 30, 106);text-align:left;"
                      ></i>
                    </li>
                    <li class="my-2">
                      <span>
                        Full integration with Xero
                        <i
                          class="fa-regular fa-circle-question"
                          v-b-tooltip.hover
                          title="Omnimoda integrates with contact and invoice data"
                        ></i></span
                      ><i
                        class="fa-solid fa-circle-check mt-1"
                        style="color:rgb(11, 30, 106);text-align:left;"
                      ></i>
                    </li>
                    <li class="mb-5">
                      <span style="font-weight:bolder;"
                        >{{
                          price.credits == 0
                            ? "1000 starter credits"
                            : price.credits + " credits added every month"
                        }}
                        <i
                          class="fa-regular fa-circle-question"
                          v-b-tooltip.hover
                          :title="
                            price.credits == 0
                              ? 'Pay as you go subscriptions manage their credits with Top Ups.'
                              : 'Approximately ' +
                                price.credits / 20 +
                                ' messages. Credits are issued on the monthly anniversary of the subscription and never expire.'
                          "
                        ></i
                      ></span>
                    </li>
                  </ul>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>

      <stripe-checkout
        ref="checkoutRef"
        :pk="this.checkoutID"
        :session-id="this.sessionID"
      />
    </div>
  </div>
</template>

<script>
//this is a hack peice of crap this component. zero forethought went into this. <- the creator.

import { StripeCheckout } from "@vue-stripe/vue-stripe";

import { ToggleButton } from "vue-js-toggle-button";

export default {
  components: {
    ToggleButton,
    StripeCheckout
  },
  name: "pricingaccount",
  props: {
    showHeader: { type: Boolean, default: true },
    hideFooter: { type: Boolean, default: false }
  },
  data() {
    return {
      term: "",
      focused: 0,
      planVal: 0,
      stripeID: "",
      resp: "",
      sessionID: "",
      accountData: "",
      test: 0,
      credits: 0,
      planValue: 0,
      stripeCustID: null,
      subsSession: null,
      checkoutID: process.env.VUE_APP_STRIPE_CHECKOUT,
      prices: [
        {
          price: { monthly: 20, annual: 0 },
          pricecode: {
            monthly: process.env.VUE_APP_PRICING_20,
            annual: process.env.VUE_APP_PRICING_20
          },
          credits: 2000,
          name: "Early Adopter",
          button1: "Special discount for the first few subscribers",
          activeSub: false,
          show: false
        },
        {
          price: { monthly: 25, annual: 250 },
          pricecode: {
            monthly: process.env.VUE_APP_PRICING_25,
            annual: process.env.VUE_APP_PRICING_250
          },
          credits: 0,
          name: "Pay as you go",
          button1: "For low volume users. Buy credit top-ups as you need them.",
          activeSub: false,
          show: true
        },
        {
          price: { monthly: 35, annual: 350 },
          pricecode: {
            monthly: process.env.VUE_APP_PRICING_35,
            annual: process.env.VUE_APP_PRICING_350
          },
          credits: 1600,
          button1: "For users who issue up to 50 invoices/month",
          name: "Everyday",
          activeSub: false,
          show: true
        },
        {
          price: { monthly: 50, annual: 500 },
          pricecode: {
            monthly: process.env.VUE_APP_PRICING_50,
            annual: process.env.VUE_APP_PRICING_500
          },
          credits: 3500,
          button1: "For users who issue 50+ invoices/month",
          name: "Everyday Plus",
          activeSub: false,
          show: true
        }
      ],

      isMonthly: true,
      isMonthlyCurrentPlan: true,
      switchplan: false
    };
  },
  methods: {
    getCurrentPlan() {
      let url =
        process.env.VUE_APP_BASE_API_URL +
        "auth/" +
        this.$store.getters.getUserGUID +
        "/account/getCurrentPlan";

      this.axios.get(url, { withCredentials: true }).then(response => {
        this.planValue = response.data;
      });
    },
    notificationToast() {
      this.$root.$bvToast.toast(`You're already subscribed to this plan`, {
        title: "Note",
        variant: "info",
        toaster: "b-toaster-top-center",
        solid: true
      });
    },
    getcurrentPlanDetails() {
      //this is hacky and replies on the only being one instance of a given price.

      if (!this.planValue) {
        return [
          {
            price: { monthly: 0, annual: 0 },
            pricecode: { month: "0" },
            credits: 0,
            name: "No subscription",
            button1: "0",
            activeSub: false
          }
        ];
      }

      this.isMonthlyCurrentPlan = Number(this.planValue.value) / 100 < 100;
      this.stripeCustID = this.planValue.stripeCustID;

      let plan = [];

      plan = this.prices.filter(r => {
        return (
          parseInt(r.price.monthly) == parseInt(this.planValue.value) / 100 ||
          parseInt(r.price.annual) == parseInt(this.planValue.value) / 100
        );
      });

      if (plan.length == 0) {
        return [
          {
            price: { month: 0, year: 0 },
            pricecode: { month: process.env.VUE_APP_PRICING },
            credits: 0,
            name: "Early Adopter",
            button1: "Special discount plan for first user",
            activeSub: true
          }
        ];
      } else {
        return plan;
      }
    },
    getStripeSession(p) {
      //check to prevent changing to current plan.

      let currentPlan = this.getcurrentPlanDetails();

      //get current plan

      if (this.switchplan == true) {
        let plan = currentPlan.filter(r => {
          return (
            (r.pricecode.monthly == p) &
              (parseInt(r.price.monthly) ==
                parseInt(this.planValue.value) / 100) ||
            (r.pricecode.annual == p) &
              (parseInt(r.price.annual) == parseInt(this.planValue.value) / 100)
          );
        });

        if (plan.length > 0) {
          this.notificationToast();
          return;
        }

        let url =
          process.env.VUE_APP_BASE_API_URL +
          "auth/" +
          this.$store.getters.getUserGUID +
          "/account/changePlan";

        let self = this;

        this.axios
          .post(
            url,
            {
              price: p,
              stripeCustID: self.stripeCustID
            },
            { withCredentials: true }
          )
          .then(response => {
            this.resp = response.data;
            this.getCurrentPlan();
            return;
          });

        return;
      }

      //new plan

      let url = process.env.VUE_APP_BASE_API_URL + "stripe";

      this.axios
        .post(
          url,
          {
            price: p,
            mode: "subscription"
          },
          { withCredentials: true }
        )
        .then(response => {
          this.resp = response.data;
          this.sessionID = response.data.id;
          this.gotoCheckout();
        });
    },
    getPrice(d) {
      if (this.isMonthly) {
        return d.monthly;
      } else {
        return d.annual;
      }
    },
    gotoCheckout() {
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout();
    },
    priceToggle() {
      this.isMonthly = !this.isMonthly;
    }
  },
  mounted() {
    this.getCurrentPlan();
  },
  computed: {
    paymentPeriod() {
      if (this.isMonthly) {
        return "month";
      }

      return "year";
    },
    tttitle() {
      return "Credits added to your account each month. PAYG subscriptions use top-ups";
    }
  }
};
</script>

<style scoped>
.pricing-card {
  border-radius: 15px;

  width: 260px;
  max-width: 260px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  position: relative;
}

.pricing-info {
  width: 260px;
  max-width: 260px;
}

@media only screen and (max-width: 700px) {
  .pricing-card {
    width: 80%;
    max-width: 400px;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .pricing-info {
    width: 80%;
    max-width: 400px;
  }
}

.getstarted-button {
  border-radius: 8px;
  text-align: center;
  padding: 8px;
  cursor: pointer;
}

ul {
  list-style-type: none;
  width: 100%;
}

li {
  width: 100%;
  justify-content: space-between;
  display: flex;
  text-align: left;
}

.wobble {
  animation: wobble-animation 1s ease 0s 1 normal both running;
}

@keyframes wobble-animation {
  0% {
    transform: translate(0, 0);
  }

  15% {
    transform: translate(-12%, 0) rotate(-5deg);
  }

  30% {
    transform: translate(10%, 0) rotate(3deg);
  }

  45% {
    transform: translate(-7%, 0) rotate(-3deg);
  }

  60% {
    transform: translate(5%, 0) rotate(2deg);
  }

  75% {
    transform: translate(-2%, 0) rotate(-1deg);
  }

  100% {
    transform: translate(0, 0);
  }
}

.heartbeat {
  animation: heart-beat-animation 2.3s ease infinite;
}

@keyframes heart-beat-animation {
  0% {
    transform: scale(1);
    animation-timing-function: ease-in-out;
  }

  14% {
    transform: scale(1.15);
    animation-timing-function: ease-in-out;
  }

  28% {
    transform: scale(1);
    animation-timing-function: ease-in-out;
  }

  42% {
    transform: scale(1.15);
    animation-timing-function: ease-in-out;
  }

  70% {
    transform: scale(1);
    animation-timing-function: ease-in-out;
  }

  100% {
    transform: scale(1);
    animation-timing-function: ease-in-out;
  }
}
</style>
