<template>
  <div
    class="grid-container"
    v-bind:class="{ grid_container_small: sidebarSmall }"
    style="border:0px;"
  >
    <div class="row">
      <!-- side nav -->
      <div
        class="sidebar"
        style="list-style: none; position: sticky; top:0px;"
        v-bind:class="{ sidebar_small: sidebarSmall }"
      >
        <router-link to="/panel/account" class="navLink">
          <i class="far fa-user-circle pad5 mx-2 iconNav fa-fw"></i>
          <span class="d-none d-md-inline pad5 my-auto"
            >Account</span
          ></router-link
        >
        <router-link
          to="/panel/contacts"
          style="
            display: block;
            font-color: white;
            text-align: left;
            padding-top: 16px;
          "
        >
          <i class="fa-regular fa-address-book mx-2 pad5 iconNav fa-fw"></i>
          <span class="d-none d-md-inline pad5 my-auto"
            >Contacts</span
          ></router-link
        >
        <router-link to="/panel/connect" class="navLink">
          <i class="fas fa-link pad5 mx-2 iconNav fa-fw"></i>
          <span class="d-none d-md-inline pad5 my-auto"
            >Connect</span
          ></router-link
        >

        <router-link to="/panel/automations" class="navLink">
          <i class="fas fa-cogs pad5 mx-2 iconNav fa-fw"> </i>
          <span class="d-none d-md-inline pad5 my-auto"
            >Automations</span
          ></router-link
        >
        <router-link to="/panel/messages" class="navLink">
          <i class="fa-regular fa-comments mx-2 pad5 iconNav fa-fw"> </i>
          <span class="d-none d-md-inline pad5 my-auto"
            >Messages</span
          ></router-link
        >
        <!-- GROUPS NOT MVP
        <router-link to="/panel/groups" class="navLink">
          <i class="fas fa-users pad5 mx-2 iconNav fa-fw"> </i>
          <span class="d-none d-md-inline pad5 my-auto"
            >Groups</span
          ></router-link
        > -->
      </div>

      <!-- side nav router views -->
      <div style="display:flex; flex:1">
        <div
          class="main_content"
          v-bind:class="{ main_content_large: sidebarSmall }"
        >
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Panel",
  data() {
    return {
      sidebarSmall: true
    };
  },
  methods: {
    toggleSideBar: function() {
      this.sidebarSmall = !this.sidebarSmall;

      //<button @click="toggleSideBar">M</button>
    }
  }
};
</script>

<style scoped>
.grid-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  border: 2px solid rgba(0, 0, 0, 0.4);

  max-width: 10800px;
}

.row {
  display: flex;
  margin: 0px;
  flex-wrap: nowrap;
  width: 100%; /* equal height of the children */
}

.sidebar {
  min-width: 160px;
  max-width: 160px;
  height: 100%;
  background-color: #4f4f4f;
  position: relative;
  flex: 1;
  z-index: 3;
}

.main_content {
  width: calc(100% - 160px);
  height: 100%;
  display: flex;
  flex: 4;
  background: rgba(250, 240, 202, 0);
}

.pad5 {
  padding: 5px;
}

button {
  position: absolute;
  border: none;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.3);
  left: 100%;
  top: 2rem;
  transform: translateX(-50%);
  cursor: pointer;
}

a {
  color: white;
}

.navLink {
  display: block;
  text-align: left;
  padding-top: 16px;
}

.iconNav {
  min-width: 28px;
  color: rgb(0, 176, 255);
}

a:hover {
  text-decoration: none !important;
  color: rgb(0, 229, 255);
}

@media screen and (max-width: 768px) {
  .sidebar {
    max-width: 50px !important;
    height: 100%;
    min-width: 50px;
    flex: 1;
    z-index: 3;
  }

  .router-link-active {
    text-decoration: none !important;
    color: rgb(0, 176, 255) !important;
  }

  .main_content {
    width: calc(100% - 50px) !important;
    height: 100%;
    flex: 9;
    overflow-x: hidden;
  }

  .sidebar_text {
    display: none;
  }

  .grid-container {
    width: 100%;
  }
}
</style>
