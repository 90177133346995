<template>
  <div>
    MAIN - entry point - list things to do, how to find things, new notices
    dashboard cards - days till refresh credits - + equiv text, days till run
    out of credit + refresh. sms sent sms replies? invopice statistics (from
    xero - total open, total due, total overdue, count + $$) failed messages
    (number ... )
  </div>
</template>

<script>
export default {
  name: "noticeboard"
};
</script>

<style scoped></style>
