<template>
  <div>
    topup2
    <div v-for="item in products" :key="item.price">
      <docket :name="item.name" :amount="item.price" :code="item.stripeCode" />
    </div>
  </div>
</template>

<script>
import docket from "../views/topup/docket.vue";

export default {
  name: "topup",
  components: { docket },
  data() {
    return {
      products: [
        {
          name: "Topup - Lite",
          price: 10,
          stripeCode: "price_1JwlKCIU6fXqzdC56Ip1ZgGA",
          paymentType: "payment"
        },
        {
          name: "Topup - Everyday",
          price: 20,
          stripeCode: "price_1JwlKnIU6fXqzdC5RreSOPmq",
          paymentType: "payment"
        },
        {
          name: "Topup - Power User",
          price: 50,
          stripeCode: "price_1JwlL7IU6fXqzdC5kleR6zk7",
          paymentType: "payment"
        }
      ]
    };
  }
};
</script>

<style scoped></style>
