var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mt-5 mx-5",staticStyle:{"font-family":"'Open Sans'","font-size":"2em","text-align":"left","font-weight":"700"}},[_vm._v(" Groups ")]),_c('router-link',{attrs:{"to":{
      name: 'groupNew'
    }}},[_c('b-button',{staticClass:"my-5",attrs:{"variant":"outline-primary"}},[_c('i',{staticClass:"far fa-plus-square"}),_vm._v(" Create New Group")])],1),_c('div',{staticClass:"mx-auto",staticStyle:{"width":"90%"}},[_c('b-table',{attrs:{"items":_vm.groups,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(edit)",fn:function(data){return [_c('router-link',{attrs:{"to":{
            name: 'group',
            query: {
              groupID: data.item.groupID,
              groupName: data.item.groupName
            }
          }}},[_c('b-button',[_c('i',{staticClass:"far fa-edit"}),_vm._v(" EDIT GROUP")])],1)]}},{key:"cell(delete)",fn:function(data){return [_c('b-button',{attrs:{"variant":"danger"}},[_c('i',{staticClass:"far fa-times-circle"}),_vm._v(_vm._s(data.item.index)+" DELETE GROUP")])]}},{key:"cell(datecreated)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.datecreated.split("T")[0])+" ")]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }