<template>
  <div>
    <!-- placeholder componant is needed later -->
    <div
      class="mt-5 mx-5"
      style="font-family: 'Open Sans'; font-size:2em; text-align: left; font-weight:700;"
    >
      Create New Contact
    </div>
    <br />
    <div style="width:350px; margin:auto">
      <b-form @submit.prevent="submitForm">
        <b-form-group
          id="input-group-1"
          label="Business Name"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="form.businessName"
            type="text"
            placeholder="The Toy Store"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-2" label="First Name" label-for="input-2">
          <b-form-input
            id="input-2"
            v-model="form.firstName"
            type="text"
            placeholder="Jane"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-3"
          label="Middle Name"
          label-for="input-3"
          :state="null"
        >
          <b-form-input
            id="input-3"
            v-model="form.middleName"
            type="text"
            placeholder=""
          ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-4" label="Last Name" label-for="input-4">
          <b-form-input
            id="input-4"
            v-model="form.lastName"
            type="text"
            placeholder="Smith"
            required
          ></b-form-input>
        </b-form-group>
        MOBILE PHONE NUMBER
        <b-form-group>
          <VuePhoneNumberInput
            v-model="form.mobile"
            :preferred-countries="['AU', 'NZ', 'US', 'GB']"
            default-country-code="AU"
            @update="onUpdate"
          ></VuePhoneNumberInput>
        </b-form-group>
        <div v-if="error.state">
          <b-alert show variant="info">{{ this.error.message }}</b-alert>
        </div>
        <b-button class="m-5" squared type="SUBMIT" variant="outline-dark"
          >CREATE CONTACT</b-button
        >
      </b-form>
    </div>
  </div>
</template>

<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import "../../../node_modules/vue-phone-number-input/dist/vue-phone-number-input.css";

export default {
  name: "contactNew",
  components: {
    VuePhoneNumberInput
  },
  data() {
    return {
      response: "",
      results: {},
      form: {
        businessName: "",
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        skype: "",
        comment: "",
        validation: {},
        mobile: ""
      },
      numberExists: "",
      error: { state: false, message: "" },
      status: ""
    };
  },
  methods: {
    submitForm(event) {
      event.preventdefault;

      let userGUID = this.$store.getters.getUserGUID;

      //validate form of number.

      //validate number exist and if validates then load?

      let data = { ...this.form, ...this.results };

      if (!this.results.isValid) {
        this.error.state = true;
        this.error.message = "invalid phone number format";

        return;
      }

      this.error.state = false;
      this.axios
        .post(
          process.env.VUE_APP_BASE_API_URL +
            "auth/" +
            userGUID +
            "/validateMobile",
          {
            num: this.results.phoneNumber,
            cc: this.results.countryCode
          },
          { withCredentials: true }
        )
        .then(response => {
          this.form.validation = JSON.parse(response.data);
        })
        .then(() => {
          const that = this;

          if (this.form.validation.valid) {
            this.axios
              .post(
                process.env.VUE_APP_BASE_API_URL +
                  "auth/" +
                  userGUID +
                  "/contact",
                {
                  data
                },
                { withCredentials: true }
              )
              .then(response => {
                that.response = response.data;

                that.status = "contact created";

                this.successToast(that.form.firstName, that.form.lastName);

                this.navigateBack();
              });
          }
        });
    },
    onUpdate(payload) {
      this.results = payload;
    },
    successToast(fname, lname) {
      this.$root.$bvToast.toast(`Contact ${fname} ${lname} Created`, {
        title: "Success",
        variant: "success",
        toaster: "b-toaster-bottom-right",
        solid: true
      });
    },
    navigateBack() {
      this.$router.push({ path: "contacts", query: { refresh: true } });
    }
  }
};
</script>
