<template>
  <div>
    <h1>CONTACT</h1>
    <div class="card mx-auto" style="width:60%;">
      <h5>Contact Details</h5>
      <ul style="list-style-type: none;">
        <li class="my-2">
          <b-row>
            <b-col
              ><span style="float:right;">Contact Business Name:</span></b-col
            ><b-col
              ><span style="float:left;">{{
                contact[0].contactBusinessName
              }}</span></b-col
            ></b-row
          >
        </li>
        <li class="my-2">
          <b-row>
            <b-col><span style="float:right;">Contact Firstname:</span></b-col
            ><b-col
              ><span style="float:left;">{{
                contact[0].contactFirstName
              }}</span></b-col
            ></b-row
          >
        </li>
        <li class="my-2">
          <b-row>
            <b-col><span style="float:right;">Contact Surname:</span></b-col
            ><b-col
              ><span style="float:left;">{{
                contact[0].contactLastName
              }}</span></b-col
            ></b-row
          >
        </li>
        <li class="my-2">
          <b-row>
            <b-col
              ><span style="float:right;">Contact Source System</span></b-col
            ><b-col
              ><span style="float:left;">{{ contact[0].source }}</span></b-col
            ></b-row
          >
        </li>
      </ul>
    </div>
    LINK TO SOURCE System - direct to contact + info for user action. perhaps
    trigger refresh? Also - pull supplier/customer rule and display.
    <br />
    <div class="card">
      <ul>
        <li>Phonenumber: {{ contact[0].contactCleanPhoneNumber }}</li>
      </ul>
    </div>
    <br />
    <div class="card">
      <div v-for="group in groups" :key="group.id">
        {{ group.groupName }}
        <router-link
          :to="{
            name: 'group',
            query: {
              groupID: group.groupID,
              groupName: group.groupName
            }
          }"
        >
          EDIT GROUP
        </router-link>
      </div>
    </div>
    <br />
    <div class="card">
      <div v-for="(message, index) in messages" :key="index">
        {{ message.direction }} {{ message.messageCount }}
      </div>
      <router-link
        :to="{
          name: 'messages',
          query: {
            contactID: contact[0].contactID,
            contactName: contact[0].contactBusinessName
          }
        }"
      >
        View Messages
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "contact",
  data() {
    return {
      text: "",
      details: {},
      groups: {},
      messages: {}
    };
  },
  computed: {
    contact() {
      return this.$store.getters.getContact(this.$route.query.contactID);
    }
  },
  methods: {
    getDetails() {
      //place holder if we need anything beyond what's already collected in the contact array.
      let userGUID = this.$store.getters.getUserGUID;

      this.axios
        .get(
          process.env.VUE_APP_BASE_API_URL +
            "auth/" +
            userGUID +
            "/contact/" +
            this.$route.query.contactID,
          {
            withCredentials: true
          }
        )
        .then(response => (this.details = response.data));
    },

    getGroups() {
      let userGUID = this.$store.getters.getUserGUID;

      this.axios
        .get(
          process.env.VUE_APP_BASE_API_URL +
            "auth/" +
            userGUID +
            "/contact/groups/" +
            this.$route.query.contactID,
          {
            withCredentials: true
          }
        )
        .then(response => (this.groups = response.data));
    },

    getMessageNumber() {
      let userGUID = this.$store.getters.getUserGUID;

      this.axios
        .get(
          process.env.VUE_APP_BASE_API_URL +
            "auth/" +
            userGUID +
            "/contact/messages/" +
            this.$route.query.contactID,
          {
            withCredentials: true
          }
        )
        .then(response => (this.messages = response.data));
    }
  },

  mounted() {
    //this.getDetails();
    this.getGroups();
    this.getMessageNumber();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
