var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',[_vm._v("CONTACT")]),_c('div',{staticClass:"card mx-auto",staticStyle:{"width":"60%"}},[_c('h5',[_vm._v("Contact Details")]),_c('ul',{staticStyle:{"list-style-type":"none"}},[_c('li',{staticClass:"my-2"},[_c('b-row',[_c('b-col',[_c('span',{staticStyle:{"float":"right"}},[_vm._v("Contact Business Name:")])]),_c('b-col',[_c('span',{staticStyle:{"float":"left"}},[_vm._v(_vm._s(_vm.contact[0].contactBusinessName))])])],1)],1),_c('li',{staticClass:"my-2"},[_c('b-row',[_c('b-col',[_c('span',{staticStyle:{"float":"right"}},[_vm._v("Contact Firstname:")])]),_c('b-col',[_c('span',{staticStyle:{"float":"left"}},[_vm._v(_vm._s(_vm.contact[0].contactFirstName))])])],1)],1),_c('li',{staticClass:"my-2"},[_c('b-row',[_c('b-col',[_c('span',{staticStyle:{"float":"right"}},[_vm._v("Contact Surname:")])]),_c('b-col',[_c('span',{staticStyle:{"float":"left"}},[_vm._v(_vm._s(_vm.contact[0].contactLastName))])])],1)],1),_c('li',{staticClass:"my-2"},[_c('b-row',[_c('b-col',[_c('span',{staticStyle:{"float":"right"}},[_vm._v("Contact Source System")])]),_c('b-col',[_c('span',{staticStyle:{"float":"left"}},[_vm._v(_vm._s(_vm.contact[0].source))])])],1)],1)])]),_vm._v(" LINK TO SOURCE System - direct to contact + info for user action. perhaps trigger refresh? Also - pull supplier/customer rule and display. "),_c('br'),_c('div',{staticClass:"card"},[_c('ul',[_c('li',[_vm._v("Phonenumber: "+_vm._s(_vm.contact[0].contactCleanPhoneNumber))])])]),_c('br'),_c('div',{staticClass:"card"},_vm._l((_vm.groups),function(group){return _c('div',{key:group.id},[_vm._v(" "+_vm._s(group.groupName)+" "),_c('router-link',{attrs:{"to":{
          name: 'group',
          query: {
            groupID: group.groupID,
            groupName: group.groupName
          }
        }}},[_vm._v(" EDIT GROUP ")])],1)}),0),_c('br'),_c('div',{staticClass:"card"},[_vm._l((_vm.messages),function(message,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(message.direction)+" "+_vm._s(message.messageCount)+" ")])}),_c('router-link',{attrs:{"to":{
        name: 'messages',
        query: {
          contactID: _vm.contact[0].contactID,
          contactName: _vm.contact[0].contactBusinessName
        }
      }}},[_vm._v(" View Messages ")])],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }