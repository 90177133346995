<template>
  <div
    style="padding:30px; width:100%; "
    class="color-blue-grey-600 bg-grey-50"
  >
    <div class="w100">
      <div style="font-size:32px;" class="my-3 color-blue-grey-700">
        <div class="text-left">
          <i class="far fa-user-circle color-light-blue-700"></i> Account
        </div>
      </div>
      <b-overlay :show="!this.accountData">
        <div class="card animated animatedFadeInUp fadeInUp text-left">
          <div class=" my-3">
            <h5 class="color-grey-800">Me</h5>
            <div class="text-left ">
              <div class="px-2 mt-2  text-left">
                Name <i class="fas fa-user" style="color: #2d76b5;"></i>
              </div>
              <div class="p-2 text-left bg-grey-50" style="color:grey">
                {{ this.accountData.loginName }}
              </div>
              <div class="px-2 mt-3 text-left">
                Email <i class="fas fa-envelope" style="color: #2d76b5;"></i>
              </div>
              <div class="p-2 text-left bg-grey-50" style="color:grey">
                {{ this.accountData.email }}
              </div>
              <div class="px-2 mt-3 text-left">
                Date Joined
                <i class="far fa-calendar-check" style="color: #2d76b5;"></i>
              </div>
              <div class="p-2 text-left bg-grey-50" style="color:grey">
                {{ this.formattedDate(this.accountData.accountDate) }}
              </div>
            </div>
            <div class="my-3 mx-2">
              <div class="mr-auto">
                <b-button
                  v-b-modal.modal-prevent-closing
                  pill
                  variant="outline-primary"
                  size="sm"
                  ><i class="far fa-edit"></i> Edit details</b-button
                >
              </div>
            </div>
          </div>
        </div>

        <div class="card animated animatedFadeInUp fadeInUp">
          <h6 class="text-left color-grey-900 m-3">
            <i class="fa-solid fa-list-check color-light-blue-700"></i> Sign-up
            check list
          </h6>
          <div>
            <div style="margin:auto; text-align:left;">
              <div class="m-3" v-if="!this.accountData.loginName">
                <i class="color-red-400 far fa-solid fa-circle-exclamation"></i>
                User name

                <b-button
                  class="mx-3"
                  v-b-modal.modal-prevent-closing
                  pill
                  variant="outline-primary"
                  ><i class="far fa-edit"></i> Edit details</b-button
                >
              </div>

              <!-- user name -->

              <div class="m-3" v-if="this.accountData.loginName">
                <i class="color-green-a400 far fa-solid fa-circle-check"></i>
                User name
              </div>
              <div class="m-3" v-if="!this.accountData.emailValidatedFlag">
                <i class="color-red-400 far fa-solid fa-circle-exclamation"></i>
                Verify your email. Click the button to send a verification
                email.
                <b-button
                  @click="sendemailverification"
                  pill
                  variant="outline-primary"
                  class="mx-3"
                  ><i class="fa-regular fa-envelope"></i> Send</b-button
                >
                <div v-show="emailsent" class="color-light-green-600 mx-3">
                  <i class="fa-solid fa-share"></i> Email sent. Check your inbox
                </div>
              </div>
              <div class="m-3" v-if="this.accountData.emailValidatedFlag">
                <i class="color-green-a400 far fa-solid fa-circle-check"></i>
                Email Verified
              </div>

              <!-- subscription -->
              <div class="m-3" v-if="this.accountData.subsdate">
                <i class="color-green-a400 far fa-solid fa-circle-check"></i>
                Active subscription
              </div>
              <div v-else class="m-3">
                <i
                  class="color-red-400  far fa-solid fa-circle-exclamation"
                ></i>
                Set-up subscription
                <b-button
                  class="mx-3"
                  pill
                  variant="outline-primary"
                  @click="goto('subs')"
                  ><i class="fa-solid fa-circle-chevron-right"></i> Subscription
                  Options</b-button
                >
              </div>

              <!-- connections -->

              <div class="m-3 text-nowrap" v-if="!this.accountData.connected">
                <i
                  class="color-red-400  far fa-solid fa-circle-exclamation"
                ></i>
                Connect to Xero
                <router-link :to="{ name: 'connect' }">
                  <b-button class="mx-3" pill variant="outline-primary"
                    ><i class="fa-solid fa-circle-chevron-right"></i> Connect
                    page</b-button
                  ></router-link
                >
              </div>
              <div class="m-3" v-if="this.accountData.connected">
                <i class="color-green-a400 far fa-solid fa-circle-check"></i>
                <span> Connected to Xero</span>
              </div>

              <!-- automations -->

              <div class="m-3" v-if="!this.accountData.automations">
                <i class="color-red-400 far fa-solid fa-circle-exclamation"></i>
                <span> Set-up automation</span>
                <router-link :to="{ name: 'automations' }">
                  <b-button class="mx-3" pill variant="outline-primary"
                    ><i class="fa-solid fa-circle-chevron-right"></i> Automation
                    page</b-button
                  ></router-link
                >
              </div>
              <div class="m-3" v-if="this.accountData.automations">
                <i class="color-green-a400 far fa-solid fa-circle-check"></i>
                <span> First automation set-up</span>
              </div>
            </div>
          </div>
        </div>
      </b-overlay>

      <div class="card">
        <div class="mx-auto"><pricingAccount /></div>
      </div>
      <div class="card">
        <div class="mx-auto"><topup /></div>
      </div>
      <div class="card p-2" style="font-size: x-large;">
        <div class="row">
          <div class="mx-auto">
            Cancel Subscription
          </div>
        </div>
        <div class="mx-auto my-3">
          <b-button variant="outline-danger" size="lg" @click="cancelMsgBox"
            >Cancel Subscription</b-button
          >
        </div>
      </div>

      <!-- update details modal -->
      <b-modal
        id="modal-prevent-closing"
        ref="modal"
        title="Edit your details"
        okTitle="Save changes"
        cancelTitle="Cancel"
        @ok="handleOk"
      >
        <form ref="form">
          <b-form-group
            label="Name"
            label-for="name-input"
            invalid-feedback="Name is required"
            :state="nameState"
          >
            <b-form-input
              id="name-input"
              v-model="editName"
              :state="nameState"
              required
              class="my-3"
            ></b-form-input
          ></b-form-group>
          <b-form-group
            label="Email"
            label-for="email-input"
            invalid-feedback="email is required"
            :state="emailState"
          >
            <b-form-input
              id="email-input"
              type="email"
              v-model="editEmail"
              :state="emailState"
              required
              class="my-3"
            ></b-form-input>
          </b-form-group>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import pricingAccount from "../../views/PricingAccount";
import topup from "../../views/TopUp2";

export default {
  components: {
    pricingAccount,
    topup
  },
  name: "account",
  data() {
    return {
      emailsent: false,
      cancel: "",
      send_email_resp: "",
      nameState: true,
      emailState: true,
      editName: "",
      editEmail: "",
      resp: "",
      sessionID: "",
      accountData: "",
      test: 0,

      statsCards: [
        {
          type: "success",
          icon: "fas fa-comment-dollar",
          title: "Credit Balance",
          value: this.$store.getters.getCredits,
          footerText: "Updated now",
          footerIcon: "ti-reload"
        },
        {
          type: "success",
          icon: "ti-wallet",
          title: "Renewal",
          value: "0",
          footerText: "...",
          footerIcon: "ti-calendar"
        },
        {
          type: "warning",
          icon: "fas fa-share",
          title: "Sent last 7 days.",
          value: "..."
        },
        {
          type: "info",
          icon: "far fa-file-alt",
          title: "Open invoices",
          value: "+45"
        }
      ],
      isMonthly: false
    };
  },
  methods: {
    goto(refName) {
      var element = this.$refs[refName];
      console.log(element);
      var top = element.offsetTop;
      window.scrollTo(0, top);
    },
    showToast(message, title, variant) {
      this.$root.$bvToast.toast(message, {
        title: title,
        variant: variant,
        toaster: "b-toaster-bottom-right",
        solid: true
      });
    },
    sendemailverification() {
      let url =
        process.env.VUE_APP_BASE_API_URL +
        "auth/" +
        this.$store.getters.getUserGUID +
        "/account/sendEmailValidation";

      this.axios
        .post(url, { email: this.editEmail }, { withCredentials: true })
        .then(response => {
          this.showToast("Email sent", "Verification Email", "primary");
          this.send_email_resp = response;
          this.emailsent = true;
        });
    },
    getStripeSession() {
      let url = process.env.VUE_APP_BASE_API_URL + "stripe";

      this.axios
        .post(
          url,
          {
            price: "price_1JrgpdIU6fXqzdC5cdC21ypA",
            mode: "subscription",
            client_reference_id: "planID"
          },
          { withCredentials: true }
        )
        .then(response => {
          this.resp = response.data;
          this.sessionID = response.data.id;
        });
    },
    getPrice(d) {
      if (this.isMonthly) {
        return d.monthly;
      } else {
        return d.annual;
      }
    },
    changePlan() {
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout();
    },
    submit() {
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout();
    },
    getAccountData() {
      let url =
        process.env.VUE_APP_BASE_API_URL +
        "auth/" +
        this.$store.getters.getUserGUID +
        "/account";

      this.axios
        .get(url, { withCredentials: true })
        .then(response => {
          this.accountData = response.data;
          this.editName = response.data.loginName;
          this.editEmail = response.data.email;
        })
        .then(() => {
          this.statsCards[1].value =
            "123"; /*this.daysTillRenewal(
            this.accountData.renewaldate
          );*/
          this.statsCards[1].footerText = "Renewal date: "; //+ this.accountData.renewaldate.slice(0, 10);
        });
    },
    getLast7Days() {
      let url =
        process.env.VUE_APP_BASE_API_URL +
        "auth/" +
        this.$store.getters.getUserGUID +
        "/account/lastseven";

      this.axios.get(url, { withCredentials: true }).then(response => {
        this.statsCards[2].value = response.data.messages;
      });
    },
    priceToggle() {
      this.isMonthly = !this.isMonthly;
    },
    daysTillRenewal(subs) {
      if (subs == "") {
        return "";
      }

      let subDate = new Date(subs);

      let now = new Date();

      let days = Math.floor((subDate - now) / (1000 * 3600 * 24));

      let ret = "Today";

      if (days == 1) {
        ret = "Tomorrow";
      } else if (days > 1) {
        ret = days + " days";
      }

      return ret;
    },
    formattedDate() {
      return "123";
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler

      this.handleSubmit();
    },
    validateEmail(e) {
      //eslint-disable-next-line
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e)) {
        return false;
      } else {
        return true;
      }
    },
    validateLength(s) {
      return s.length > 0;
    },

    handleSubmit() {
      // Exit when the form isn't valid

      let inputsValid = false;

      this.emailState = !this.validateEmail(this.editEmail);
      this.nameState = this.validateLength(this.editName);
      inputsValid = this.emailState || this.nameState;

      if (!inputsValid) {
        return;
      }

      this.updateAccountData();

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },
    updateAccountData() {
      let url =
        process.env.VUE_APP_BASE_API_URL +
        "auth/" +
        this.$store.getters.getUserGUID +
        "/account/update";

      let self = this;

      this.axios
        .post(
          url,
          {
            email: this.editEmail,
            loginName: this.editName
          },
          { withCredentials: true }
        ) //eslint-disable-next-line
        .then(function(resp) {
          self.getAccountData();
        });
    },
    cancelMsgBox() {
      this.cancel = "";
      this.$bvModal
        .msgBoxConfirm("Cancel your subscription?", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          this.cancel = value;
        });
    },
    poll() {
      setInterval(this.getAccountData, 60000);
    }
  },
  mounted() {
    this.getAccountData();
    this.getLast7Days();
    this.poll();
  },
  computed: {}
};
</script>

<style>
.w100 {
  max-width: 1000px;
}

@media only screen and (max-width: 450px) {
  .w100 {
    width: 260px;
  }
}
</style>
