<template>
  <div class="mx-auto">
    <div class="fit">
      <ul class="days">
        <li
          v-for="(day, index) in dayList"
          :key="day.num"
          @click="select(index)"
          :class="{ selected: day.selected }"
        >
          {{ day.num }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "upcomingPaymentsCalender",
  data() {
    return {
      dayList: [
        { num: 7, selected: false },
        { num: 6, selected: false },
        { num: 5, selected: false },
        { num: 4, selected: false },
        { num: 3, selected: false },
        { num: 2, selected: false },
        { num: 1, selected: false },
        { num: 21, selected: false },
        { num: 14, selected: false }
      ],
      test: ""
    };
  },
  methods: {
    select(i) {
      this.dayList[i].selected = !this.dayList[i].selected;
      this.$emit(
        "automationChildData",
        "invoiceUpcomingReminder",
        this.selectedDates
      );
    },

    setDatesFromParent(d) {
      for (let day of d.days) {
        let updateThis = this.dayList.find(x => x.num == day.num);
        updateThis.selected = true;
      }
    }
  },

  computed: {
    selectedDates() {
      return this.dayList.filter(function(day) {
        return day.selected == true;
      });
    }
  }
};
</script>

<style scoped>
ul {
  list-style-type: none;
  margin: auto;
}

.fit {
  width: 400px;
}

.selected {
  border-radius: 50%;
  width: 6px;
  padding: 6px;

  background: #fff;
  border: 2px solid #666;
  color: #666;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .fit {
    width: 100%;
  }

  .selected {
    padding: 0;
  }
}

.days {
  padding: 10px 0;
  background: #eee;
  margin: 0;
}

.days li {
  list-style-type: none;
  display: inline-block;
  width: 13.6%;
  text-align: center;
  margin-bottom: 5px;
  font-size: 12px;
  color: #777;
}
</style>
