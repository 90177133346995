<template>
  <div @click="getStripeSession">
    <stripe-checkout
      ref="checkoutRef"
      pk="pk_test_51JrgmmIU6fXqzdC5Wd17sVt2xxCfOHbfySPOmjskjPdOmiii3Die920tNmIwEXGnyeJ5CUuLJ4GlIZ4DFGWfcpPa00Bc6wHwrJ"
      :session-id="this.sessionID"
    />docket! {{ name }} ${{ amount }} {{ sessionID }}
  </div>
</template>

<script>
import { StripeCheckout } from "@vue-stripe/vue-stripe";
export default {
  components: {
    StripeCheckout
  },
  name: "docket",
  props: { name: String, amount: Number, code: String, paymentType: String },
  data() {
    return { sessionID: "" };
  },
  methods: {
    getStripeSession() {
      let url = process.env.VUE_APP_BASE_API_URL + "stripe";

      let that = this;

      this.axios
        .post(
          url,
          {
            price: this.code,
            mode: "payment"
          },
          { withCredentials: true }
        )
        .then(response => {
          this.sessionID = response.data.id;
          that.$refs.checkoutRef.redirectToCheckout();
        });
    }
  }
};
</script>

<style scoped></style>
