/* eslint-disable no-mixed-spaces-and-tabs */
<template>
  <div>
    <div
      class="mt-chip"
      v-for="(chipItem, index) in currentChips"
      :key="index"
      :class="computedCssClass"
    >
      <img
        v-if="isObjectLoaded(chipItem) && chipItem.imgUrl"
        :src="chipItem.imgUrl"
        alt="No image found"
      />
      <span>{{ isObjectLoaded(chipItem) ? chipItem.value : chipItem }}</span>
      <div
        v-if="onChipRemove"
        @click="onRemoveClick(chipItem, index)"
        class="mt-close"
        alt="close"
        :class="chipType ? `mt-close-${chipType}` : ''"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "VueMaterialChips",
  props: {
    chipType: {
      type: String,
      default: () => ""
    },
    cssClass: {
      type: String,
      default: () => ""
    },
    chipData: {
      type: Array,
      required: true,
      default: () => []
    },
    onChipRemove: {
      type: Function
    }
  },
  computed: {
    computedCssClass() {
      const { chipType, cssClass } = this.$props;
      let resultClass = "";
      if (chipType) {
        resultClass = `mt-chip-${chipType}`;
      }
      if (cssClass) {
        resultClass = `${resultClass} ${cssClass}`;
      }
      return resultClass;
    },
    currentChips() {
      return this.$props.chipData;
    }
  },
  methods: {
    isObjectLoaded(obj) {
      return obj && typeof obj === "object" && Object.keys(obj).length > 0;
    },
    onRemoveClick(chipItem, chipIndex) {
      this.$props.chipData = this.$props.chipData.filter(
        (chip, index) => index != chipIndex
      );
      this.$props.onChipRemove(chipItem, this.$data.currentChips);
    }
  }
};
</script>

<style scoped>
.mt-chip {
  display: inline-block;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  line-height: 32px;
  background-color: #e4e4e4;
  margin-bottom: 5px;
  margin-right: 5px;
  position: relative;
  padding: 0px 16px !important;
  height: 34px;
  font-size: 14px;
  border-radius: 50px;
}
.mt-chip-large {
  height: 48px;
  font-size: 16px;
  border-radius: 50px;
  padding: 0px 16px !important;
}
.mt-chip-large span {
  margin-left: 4px !important;
  line-height: 46px !important;
}
.mt-chip-large .mt-close-large {
  margin-right: 6px !important;
}
.mt-chip-small {
  padding: 0px 12px !important;
  height: 34px;
  font-size: 12px;
  border-radius: 50px;
}
.mt-close {
  position: relative;
  right: -2px;
  top: 4px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  display: inline-block;
}
.mt-close:before {
  position: absolute;
  left: 8px;
  top: 1px;
  content: " ";
  height: 13px;
  width: 2px;
  background-color: #333;
  transform: rotate(45deg);
}
.mt-close:after {
  position: absolute;
  left: 8px;
  top: 1px;
  content: " ";
  height: 13px;
  width: 2px;
  background-color: #333;
  transform: rotate(-45deg);
}
.mt-close-large {
  right: -4px;
  top: 2px;
}
.mt-close-large:before {
  left: 8px;
  height: 16px;
  width: 2px;
}
.mt-close-large:after {
  left: 8px;
  height: 16px;
  width: 2px;
}
.mt-close-small {
  right: 0px;
  top: 4px;
}
.mt-close-small:before {
  left: 7px;
  top: 2px;
  height: 11px;
  width: 2px;
}
.mt-close-small:after {
  left: 7px;
  top: 2px;
  height: 11px;
  width: 2px;
}
</style>
