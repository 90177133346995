<template>
  <div style="height: 100%; width: 100%">
    <!-- frame Window -->

    <div class="rowFlexContainer color-blue-grey-600 bg-grey-50">
      <!-- inbox -->
      <div style="margin-left: -50px;">
        <div stle="position:absolute; z-index:100; ">
          <div
            @click="toggle"
            class="rounded-circle p-3 mt-1"
            :class="!inboxHide ? 'toggle-button' : 'toggle-button-toggled'"
          >
            <i
              class="fa-solid fa-arrows-up-to-line fa-lg"
              :class="{
                'fa-rotate-90': inboxHide,
                'fa-rotate-270': !inboxHide
              }"
            ></i>
          </div>
        </div>
      </div>

      <div v-bind:class="{ toggle: inboxHide }" class="inbox bg-grey-50">
        <section class="container" style="padding-left:4px;">
          <header class="header" style="margin-bottom:8px;">
            <h4 class="text-left my-3 color-blue-grey-700">
              <i class="fa-regular fa-comments color-light-blue-700"></i> Inbox
            </h4>
            <b-input-group size="sm" class="mx-auto my-auto">
              <b-form-input
                v-model="searchText"
                placeholder="search for contact"
              ></b-form-input>
              <b-input-group-append>
                <b-button :disabled="!searchText" @click="searchText = ''"
                  >Clear</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </header>
          <div class="contentInbox" style="scrollbar-width: thin;">
            <div
              class="ip mx-2"
              v-bind:class="{
                selected: m.sourceContactID == contact.sourceContactID,
                whitebgd: m.sourceContactID != contact.sourceContactID,
                notSelected: m.sourceContactID != contact.sourceContactID
              }"
              v-for="(m, idx_inbox) in inbox"
              :key="idx_inbox"
              style="font-size:12px; cursor: pointer;  border-radius: 0.25rem; box-shadow: "
            >
              <div
                @click="contactFocus(m)"
                @mouseover="isHovering = true"
                @mouseout="isHovering = false"
                :class="{ hovering: isHovering }"
              >
                <inbox-item
                  v-bind:name="m.contactBusinessName"
                  v-bind:unread="m.unreadMessages"
                  v-bind:date="m.lastMessage"
                >
                </inbox-item>
              </div>
            </div>
            <div class="card m-1 m-sm-5 p-3" v-show="inbox.length == 1">
              <div class="d-flex text-left text-wrap ">
                <i class="fa-solid fa-circle-exclamation m-1"></i> No contacts.
                Connect to a Xero file to import your contacts
              </div>
            </div>
            <br />
          </div>
          <footer id="footer"></footer>
        </section>
      </div>

      <div class="messages">
        <section class="container color-blue-grey-600 bg-grey-50 ">
          <header
            class="header mt-3"
            style="font-size: 18px; font-weight: 700; text-align:center; height: 48px;"
          >
            <h4
              class="my-auto"
              style="padding:2px; text-align:left; text-align: left; color: #3c3d41;"
            >
              <b-avatar
                size="32px"
                :text="getInitials(this.contact.contactBusinessName)"
                :variant="getVariant(this.contact.contactBusinessName)"
              ></b-avatar>
              {{
                this.contact.contactBusinessName
                  ? this.contact.contactBusinessName
                  : "all"
              }}
            </h4>
          </header>
          <article
            ref="articles"
            class="contentMessages card mb-2"
            style="scrollbar-width: thin;"
            v-scroll="onScroll"
          >
            <div>
              <div class="chat">
                <p v-for="(m, index) in messages" :key="index">
                  <messageBubble
                    v-bind:body="m.messageBody"
                    v-bind:inbound="inboundBoolean(m.direction)"
                    v-bind:dateTime="m.dateUTC"
                    sendMode="manual and system"
                    :contact="m.recipientGUID"
                    :sender="m.contactBusinessName"
                    :id="m.id"
                    :jobID="m.jobID"
                  ></messageBubble>
                </p>
              </div>
              <div
                class="card m-1 m-sm-5 p-3"
                v-show="!messages || messages.length == 0"
              >
                <div class="d-flex text-left text-wrap ">
                  <i class="fa-solid fa-circle-exclamation m-1"></i> No messages
                  yet.
                </div>
              </div>
            </div>
          </article>
          <div>
            <b-form v-on:submit.prevent="sendMessage">
              <div class="d-flex">
                <b-form-textarea
                  id="textarea-rows"
                  :placeholder="preventSendToAll"
                  rows="2"
                  size="md"
                  :disabled="preventSendToAll != 'Type your message here'"
                  v-model="sendMessageText"
                  class="control-width"
                ></b-form-textarea>
                <!--<p>
                Message length: {{ messageLength }} Number of SMS's:
                {{ numberofMgs }}
              </p>
              emoji picker
              -->
                <b-button
                  size="sm"
                  variant="outline-primary"
                  style="color: #1E91D6; border-color: #1E91D6"
                  type="submit"
                  :disabled="preventSendToAll != 'Type your message here'"
                  class="buttonoverride"
                  >send</b-button
                >
              </div>
            </b-form>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import messageBubble from "./conversationComponents/messageBubble";
import InboxItem from "./conversationComponents/inboxItem.vue";
import { EventBus } from "../../eventBus";

//import InfiniteLoading2 from "vue-infinite-loading";

export default {
  name: "messages",
  components: {
    //InfiniteLoading2,
    InboxItem,
    messageBubble
  },
  props: {
    contactID: { type: String, default: "" },
    contactName: { type: String, default: "" }
  },
  data() {
    return {
      isHovering: false,
      height: 0,
      busy: false,
      position: { scrollTop: 0, scrollLeft: 0 },
      sendMessageText: "",
      r: "",
      inboxHide: false,
      contact: { sourceContactID: "all" },
      url: "",
      searchText: "",
      response: "",
      onBehalfOf: "[ABCD]"
    };
  },
  methods: {
    getMessages(firstLoad = false) {
      this.busy = true;

      let url =
        process.env.VUE_APP_BASE_API_URL +
        "auth/" +
        this.$store.getters.getUserGUID +
        "/messages";

      if (!firstLoad && this.contact.sourceContactID != "all") {
        url = url + "/contact/" + this.contact.sourceContactID;
        this.allFlag = false;
      } else {
        this.allFlag = true;
      }

      //$state.reset();

      this.axios
        .post(
          url,
          {
            id: this.minID,
            userGUID: this.$store.state.userGUID
          },
          {
            withCredentials: true
          }
        )
        .then(response => {
          let r = response.data;
          if (r.length > 0) {
            if (this.allFlag) {
              this.$store.commit("storeAllMessages", {
                r
              });
            } else {
              let p = { data: r, contact: this.contact.sourceContactID };

              this.$store.commit("storeMessagesRecipientGrouped", { p });
            }
          }
        })
        .then(() => {
          this.busy = false;
          this.$refs.articles.scrollTop =
            this.$refs.articles.scrollHeight - this.height;
        });
    },
    scollToBottom() {
      this.$refs.articles.scrollTop = this.$refs.articles.scrollHeight;
    },
    getInbox() {
      if (this.$store.state.inbox.length > 1) {
        return;
      }

      this.axios
        .post(
          process.env.VUE_APP_BASE_API_URL +
            "auth/" +
            this.$store.getters.getUserGUID +
            "/inbox",
          {
            offset: this.offsetInbox
          },
          {
            withCredentials: true
          }
        )
        .then(response => {
          let r = response.data;

          if (r.length > 0) {
            this.$store.commit("storeInbox", {
              r
            });
            //$state.loaded();
          } else {
            //$state.complete();
          }
        });
    },
    decode_utf8(s) {
      return decodeURIComponent(escape(s));
    },
    inboundBoolean(b) {
      if (b == "in" || b == "IN") {
        return true;
      } else {
        return false;
      }
    },
    contactFocus(c) {
      if (c.sourceContactID == "all") {
        this.contact = { sourceContactID: "all" };
      } else {
        this.contact = c;
      }

      if (this.messages == null) {
        this.getMessages();
      }

      this.$nextTick(() => {
        this.$refs.articles.scrollTop = this.$refs.articles.scrollHeight;
      });
    },

    sendMessage() {
      if (this.messageLength == 0 || this.contact.sourceContactID == "all") {
        return;
      }

      this.axios
        .post(
          process.env.VUE_APP_BASE_API_URL +
            "auth/" +
            this.$store.getters.getUserGUID +
            "/sendMessage",
          {
            userGUID: this.$store.getters.getUserGUID,
            recipientGUID: this.contact.sourceContactID,
            message: this.sendMessageText,
            phoneNumber: this.contact.cleanPhoneNumber,
            senderCompanyName: this.contact.organisationName,
            recipientName: this.contact.contactBusinessName
          },
          { withCredentials: true }
        )
        .then(response => {
          this.r = response;
          this.sendMessageText = "";

          //push message to array
        });
    },
    toggle() {
      this.inboxHide = !this.inboxHide;
    },
    onScroll(e, position) {
      this.position = position;
      if (position.scrollTop == 0) {
        this.height = this.$refs.articles.scrollHeight;
        this.getMessages();

        //need to position scroll bar + handle first load.
      }
    },
    focusOnNewestMessage() {
      var container = this.$refs.articles;
      setTimeout(function() {
        container.scrollTop = container.scrollHeight;
      }, 1000);
    },
    getInitials(str) {
      let s = str ? str : "all";

      var matches = s.match(/\b(\w)/g);
      return matches.join("").substring(0, 3);
    },
    getVariant(str) {
      let initials = this.getInitials(str);

      let i = initials.charCodeAt(0);

      let i2 = initials.charCodeAt(1) ? initials.charCodeAt(1) : 0;

      let i3 = initials.charCodeAt(2) ? initials.charCodeAt(2) : 0;

      var options = [
        "secondary",
        "primary",
        "dark",
        "light",
        "success",
        "danger",
        "warning",
        "info"
      ];

      return options[(i + i2 + i3) % 8];
    }
  },
  computed: {
    preventSendToAll() {
      if (!this.contact) {
        return "no contacts";
      }

      if (this.contact.sourceContactID == "all") {
        return "cannot send to all contacts";
      }

      return "Type your message here";
    },
    messages() {
      if (this.contact.sourceContactID == "all") {
        let m = this.$store.getters.getAllMessages;
        return m;
      } else {
        let m = this.$store.getters.getMessages[this.contact.sourceContactID];

        return m;
      }
    },
    numberofMessages() {
      return this.messages().length;
    },
    inbox() {
      let c = this.$store.getters.getInbox;
      let f = c.filter(name =>
        name.contactBusinessName
          .toLowerCase()
          .includes(this.searchText.toLowerCase())
      );

      return f;
    },

    minID() {
      //need to collect this at the recipient/all levels

      if (this.contact.sourceContactID in this.$store.getters.getMessages) {
        let c = this.$store.getters.getMessages[this.contact.sourceContactID];

        if (c === "undefined" || c.length == 0) {
          return 1000000000;
        }

        let record = c.reduce(function(prev, curr) {
          return prev.id < curr.id ? prev : curr;
        });

        return record.id;
      } else {
        return 1000000000;
      }
    },
    offsetInbox() {
      let c = this.$store.getters.getInbox.length - 1;

      return c;
    },
    aUser() {
      if (this.$route.query.contactID) {
        return true;
      } else {
        return false;
      }
    },
    messageLength() {
      //logic to add - case if all, case when contact name is null, use business name.

      let s = 0;

      if (this.contact.contactBusinessName) {
        s = this.contact.contactBusinessName.length;
      }

      return this.sendMessageText.length + 2 + s;
    },
    numberofMgs() {
      return Math.ceil(this.sendMessageText.length / 160);
    }
  },

  mounted() {
    //first time all message caller:

    if (this.$route.query && Object.keys(this.$route.query).length != 0) {
      alert(JSON.stringify(this.$route.query));

      this.contact = {
        sourceContactID: this.$route.query.contactID,
        contactBusinessName: this.$route.query.contactName
      };
    }

    this.getInbox();
    this.getMessages(true);
    this.contactFocus(this.contact);

    EventBus.$on("scrollToBottom", this.focusOnNewestMessage);
  },
  beforeDestroy() {
    EventBus.$off("scrollToBottom", this.focusOnNewestMessage);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.chat {
  background: white;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 2;
  box-sizing: border-box;
  box-shadow: 0 0 8rem 0 rgba(black, 0.1), 0rem 2rem 4rem -3rem rgba(black, 0.5);
}

.rowFlexContainer {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.inbox {
  margin-left: 0px;
  width: 400px;
  float: left;
  transition: all 0.15s ease-in-out;
}

.toggle {
  margin-left: -400px;
  transition: all 0.15s ease-in-out;
}

.toggle-button {
  cursor: pointer;
  left: 380px;
  position: relative;
  background: #ffffffe0;
  z-index: 20;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transition: all 0.15s ease-in-out;
}

.toggle-button-toggled {
  cursor: pointer;
  left: 480px;
  position: relative;
  background: #ffffffe0;
  z-index: 20;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transition: all 0.15s ease-in-out;
}

.contentInbox {
  flex: 1 1 auto;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  overflow-x: hidden;
  color: rgb(97, 97, 97);
}

.contentMessages {
  flex: 1 1 auto;
  max-height: calc(100vh - 260px);
  overflow-y: auto;
  overflow-x: hidden;
  background: white;
}

@media only screen and (max-width: 450px) {
  .inbox {
    width: 305px;
  }
  .toggle {
    margin-left: -320px;
    transition: all 0.2s ease-in-out;
  }

  .toggle-button {
    left: 300px;
  }

  .toggle-button-toggled {
    left: 300px;
  }

  .contentMessages {
    width: 280px;
  }

  .header {
    overflow-x: hidden;
    width: 280px;
  }

  .control-width {
    width: 228px;
  }
}
.messages {
  flex: 1 1 auto;
  overflow-x: hidden;
  white-space: nowrap;
  background: white;
  min-width: 340px;
  max-width: 500px;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* media queries to deal with this */

.chatWindowInboxVisible {
  width: calc(100% - 400px);
}

.chatwindowInboxHidden {
  width: 100%;
}

.notSelected {
}

.selected {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background: white;
}

.header {
  margin: 5px;
}

.whitebgd {
}

.buttonoverride {
}

.buttonoverride:hover {
  background-color: #1e91d6 !important;
  color: #ddfdfe !important;
}
</style>
