<template>
  <div style="color:rgb(97, 97, 97)">
    <div
      class="py-2"
      style="width:100%; margin-top:10px;margin-bottom:10px; 
  border-radius: 0.25rem;"
      @mouseover="isHovering = true"
      @mouseout="isHovering = false"
      :class="{ hovering: isHovering }"
    >
      <div
        style="display:flex; width:100%; min-height: 0; overflow: hidden; padding-left: 7px;"
      >
        <div class="my-auto mx-auto" style="width:50px">
          <b-avatar
            v-if="this.unread > 0"
            size="50px"
            :text="getInitials(this.name)"
            :variant="getVariant(this.name)"
            :badge="this.unread + ''"
            badge-top
            badge-variant="primary"
          ></b-avatar>
          <b-avatar
            v-else
            size="50px"
            :text="getInitials(this.name)"
            :variant="getVariant(this.name)"
          ></b-avatar>
        </div>
        <div id="list body" style="width:calc(100% - 50px); padding-left:9px;">
          <div id="details">
            <h4
              id="heading"
              class="d-flex"
              style="width:100%; min-height: 0; overflow: hidden;"
            >
              <span
                id="title"
                style="letter-spacing: -.5px; line-height:2;min-width: 0; display: block; font-size: 18px; margin-top:10px; text-overflow: ellipsis; white-space:nowrap; overflow:hidden; max-width:200px"
              >
                {{ this.name }}
              </span>
              <div
                v-show="this.name != 'all'"
                id="date"
                style="color: rgb(117, 117, 117);
                letter-spacing: 0.1px;
                font-size: 14px;
                margin-left: auto;
                margin-right: 16px;
                margin-top: 2px;
                max-width: 80px;
                font-weight: 600;"
              >
                <small
                  ><div style="display: block ruby;">Last message:</div>
                  <STRONG>{{ this.readableDate }}</STRONG></small
                >
              </div>
            </h4>
          </div>
          <!--
          <div
            style="min-width: 0; display: block; text-overflow: ellipsis; white-space:nowrap; overflow:hidden; width:240px; padding-right:15px;"
          >
            the most recent message goes here and here and here
            andhereeeeeeeeeee
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

export default {
  name: "inboxItem",
  props: {
    name: {
      type: String,
      default: "no name"
    },
    unread: {
      type: Number,
      default: 0
    },
    date: {
      type: [String, Date],
      default: ""
    }
  },
  data() {
    return {
      isHovering: false,
      now: ""
    };
  },
  methods: {
    getInitials(str) {
      var matches = str.match(/\b(\w)/g);
      return matches.join("").substring(0, 3);
    },
    getVariant(str) {
      let initials = this.getInitials(str);

      let i = initials.charCodeAt(0);

      let i2 = initials.charCodeAt(1) ? initials.charCodeAt(1) : 0;

      let i3 = initials.charCodeAt(2) ? initials.charCodeAt(2) : 0;

      var options = [
        "secondary",
        "primary",
        "dark",
        "light",
        "success",
        "danger",
        "warning",
        "info"
      ];

      return options[(i + i2 + i3) % 8];
    }
  },
  computed: {
    showUnreadMessages() {
      if (this.unread > 0) {
        return this.unread;
      } else {
        return false;
      }
    },
    readableDate() {
      let d = dayjs(this.date).fromNow(this.now);

      if (d == "NaN years") {
        return "No messages";
      } else {
        return d + " ago";
      }
    }
  },
  created() {
    dayjs.extend(relativeTime);

    this.now = new Date().toISOString().slice(0, 18);

    setInterval(
      function() {
        this.now = new Date().toISOString().slice(0, 18);
      }.bind(this),
      2000
    );
  }
};
</script>

<style scoped>
.hovering {
  background-color: white;
  color: black;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
</style>
