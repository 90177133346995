<template>
  <div>NEW AUTOMATION</div>
</template>

<script>
export default {
  name: "automationNew",
};
</script>

