<template>
  <div>
    <div>NEW SMS GROUP</div>

    <div style="width:350px; margin:auto">
      <b-form @submit.prevent="submitForm">
        <b-form-group id="input-group-1" label="Group Name" label-for="input-1">
          <b-form-input
            id="input-1"
            v-model="form.groupName"
            type="text"
            placeholder="Xmas list or Local Customers"
            required
          ></b-form-input>
        </b-form-group>
        <b-button class="m-5" squared type="SUBMIT" variant="outline-dark"
          >CREATE SMS GROUP</b-button
        >
      </b-form>
      {{ this.form.groupName }}
      MEMBERS
      <VueMaterialChips
        :chip-data="chipList"
        :on-chip-remove="onChipRemove"
      ></VueMaterialChips>
      Search
      <b-form-group
        label-for="filter-input"
        v-model="filter"
        type="search"
        placeholder="Type to Search"
        class="mt-3 mx-auto"
        style="max-width:400px;"
      >
        <b-input-group size="sm" class="mx-auto my-auto">
          <b-form-input
            id="filter-input"
            v-model="filter"
            type="search"
            placeholder="Type to Search"
          ></b-form-input>

          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>

      <b-table
        ref="contacts"
        :items="contacts"
        :fields="fields"
        :filter="filter"
        :current-page="currentPage"
        :per-page="perPage"
        class="frameFocus mt-3"
        @filtered="onFiltered"
        hover
        @row-clicked="(item, index, event) => selectRow(item, index, event)"
      >
        <template #cell(contactname)="contacts">
          {{ contacts.item.contactFirstName }}
          {{ contacts.item.contactLastName }}
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import VueMaterialChips from "../panel/otherComponents/chips";

export default {
  components: { VueMaterialChips },
  name: "groupNew",
  data() {
    return {
      form: {
        groupName: ""
      },
      response: "",
      selectedContacts: [],
      sortBy: "contactBusinessName",
      sortDesc: false,
      fields: [
        { key: "contactBusinessName", label: "Business Name", sortable: true },
        { key: "contactname", label: "Contact Name" }
      ],
      text: "",
      filter: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 20
    };
  },
  methods: {
    submitForm(event) {
      event.preventdefault;

      let userGUID = this.$store.getters.getUserGUID;

      let d = this.form;
      let m = this.selectedContacts;
      const that = this;

      this.axios
        .post(
          process.env.VUE_APP_BASE_API_URL + "auth/" + userGUID + "/group",
          {
            d,
            m
          },
          { withCredentials: true }
        )
        .then(function(response) {
          that.response = response;

          that.successToast(d.groupName);
          that.navigateBack();
        });
    },
    successToast(gname) {
      this.$root.$bvToast.toast(`Group list ${gname} created`, {
        title: "Success",
        variant: "success",
        toaster: "b-toaster-bottom-right",
        solid: true
      });
    },
    navigateBack() {
      this.$router.push({ path: "groups", query: { refresh: true } });
    },
    refreshContactList() {
      this.axios
        .get(
          process.env.VUE_APP_BASE_API_URL +
            "auth/" +
            this.$store.getters.getUserGUID +
            "/contacts",
          {
            withCredentials: true
          }
        )
        .then(response => {
          //push into store

          let r = response.data;

          this.$store.commit("storeContacts", {
            r
          });
        });
    },
    onChipRemove(chiItem) {
      //chiItem gives you the removed currentChip
      //currentChips gives you all the remaning chips in the chipData

      let idx = this.selectedContacts.findIndex(e => e.name === chiItem);

      let cid = this.selectedContacts[idx].id;

      this.$store.commit("toggleContactStyling", {
        cid
      });

      this.selectedContacts.splice(idx, 1);
    },
    selectRow(payload) {
      let cid = payload.contactID;

      let idx = this.selectedContacts.findIndex(
        e => e.id === payload.contactID
      );

      if (idx === -1) {
        this.selectedContacts.push({
          name: payload.contactBusinessName,
          id: payload.contactID
        });
      } else {
        this.selectedContacts.splice(idx, 1);
      }

      this.$store.commit("toggleContactStyling", {
        cid
      });
      // add formatting to contact data - neet to think about cleaning styling flags, and creating styling flags on group edit.
    }
  },
  computed: {
    contacts() {
      return this.$store.getters.getContacts;
    },
    chipList() {
      if (this.selectedContacts.length == 0) {
        return [];
      } else {
        return [...new Set(this.selectedContacts.map(({ name }) => name))];
      }
    }
  },
  mounted() {
    this.refreshContactList();
  }
};
</script>
