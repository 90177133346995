<template>
  <div style="padding:30px; width:100% " class="color-blue-grey-600 bg-grey-50">
    <div style="max-width:1000px;">
    <div style="font-size:32px;" class="my-3 color-blue-grey-700">
      <div class="text-left">
        <i class="fa-solid fa-link color-light-blue-700"></i> Manage Connections
      </div>
    </div>
    <div class="card">
      <h6 class="text-left color-grey-900 m-3">
        <i class="fa-solid fa-plug color-light-blue-700"></i> Connect to Xero
      </h6>
      <p class="text-left color-grey-600" style="font-size:14px">
        <ul>
          <li class="my-1">Click the <span class="color-grey-800">connect</span> button to begin the connection steps.</li>
        <li class="my-1">You will be directed to a Xero sign in portal. From here you can <span class="color-grey-800">link</span> an
        organisation to Omnimoda.</li><li class="my-1">If you have <span class="color-grey-800">multiple</span> organisations, repeat the
        connection process for each organisaiton.</li><li class="my-1">If you want to <span class="color-grey-800">reconnect</span>, click the connect to Xero button
        to link the organisation back to Omnimoda.</li></ul>
      </p>
      <b-row class="m-1 text-left">
        <b-link
          href="https://login.xero.com/identity/connect/authorize?response_type=code&client_id=A9E966878541460C8F7087017D00856C&redirect_uri=https://www.omnimoda.co/connect/redirect/xero&scope=offline_access accounting.contacts accounting.transactions.read accounting.settings.read"
        >
          <img
            :src="imageSrc.xero"
            alt="no image"
            style="height:80px;"
            class="mr-auto my-auto grow"
          /><b-button pill variant="outline-primary">Connect to Xero </b-button>
        </b-link> </b-row
      ><!--<row class="mt-1 mx-5">
            <b-link
              href="https://secure.myob.com/oauth2/account/authorize?client_id=2cf6aa74-1499-4a2e-92b0-ecdbd998638f&redirect_uri=https://omnimoda.co/connect/redirect/myob &response_type=code&scope=CompanyFile"
              ><b-button
                variant="outline-primary"
                class="mx-auto my-auto"
                style="border-color:purple; color:purple;"
                >CONNECT TO MYOB
                <b-icon
                  v-if="isHovered"
                  icon="chevron-double-right"
                  variant="primary"
                ></b-icon>
                <b-icon
                  v-else
                  icon="chevron-double-right"
                  style="color: 000;"
                ></b-icon>
              </b-button>
              <img
                :src="imageSrc.myob"
                alt="no image"
                style="height:50px;"
                class="mx-auto my-auto grow"
            /></b-link>
          </row> -->
    </div>
    <connectedOrgs></connectedOrgs>
  </div></div>
</template>

<script>
import connectedOrgs from "../connect/connections";

export default {
  name: "Connect",
  components: { connectedOrgs },
  data() {
    return {
      text: "",
      isHovered: false,
      imageSrc: {
        xero:
          "https://omnimodaStorage.b-cdn.net/Xero%20-%20Logo%20-%20Blue.png",
        myob: "https://omnimodaStorage.b-cdn.net/MYOB.png"
      }
    };
  },

  props: {},

  methods: {
    testGetOrgListFromXero() {
      this.axios
        .get(
          process.env.VUE_APP_BASE_API_URL +
            "auth/" +
            this.$store.getters.getUserGUID +
            "/connect/connection/listOrgsFromXero",
          {
            withCredentials: true
          }
        )
        .then(response => (this.text = response.data));
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
