<template>
  <div>
    <h1>AUTOMATION</h1>

    {{ this.$route.query.automationID }} {{ this.$route.query.automationName }}

    <div>
      day card placeholder
    </div>
  </div>
</template>

<script>
export default {
  name: "automation",
  data() {
    return {
      text: "",
      automation: {}
    };
  },
  methods: {
    testGETGroups() {
      this.axios
        .get(process.env.VUE_APP_BASE_API_URL + "automation", {
          withCredentials: true
        })
        .then(response => (this.automation = response.data));
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
