<template>
  <div>
    <div class="mx-auto">
      <div class="fit">
        <ul class="days">
          <li
            v-for="(day, index) in dayList"
            :key="day.num"
            @click="select(index)"
            :class="{ selected: day.selected }"
          >
            {{ day.num }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "overduePaymentsCalender",
  data() {
    return {
      dayList: [
        { num: 1, selected: false },
        { num: 2, selected: false },
        { num: 3, selected: true },
        { num: 4, selected: false },
        { num: 5, selected: false },
        { num: 6, selected: false },
        { num: 7, selected: false },
        { num: 8, selected: false },
        { num: 9, selected: false },
        { num: 10, selected: true },
        { num: 11, selected: false },
        { num: 12, selected: false },
        { num: 13, selected: false },
        { num: 14, selected: false },
        { num: 15, selected: false },
        { num: 16, selected: false },
        { num: 17, selected: false },
        { num: 18, selected: false },
        { num: 19, selected: false },
        { num: 20, selected: false },
        { num: 21, selected: true },
        { num: 22, selected: false },
        { num: 23, selected: false },
        { num: 24, selected: false },
        { num: 25, selected: false },
        { num: 26, selected: false },
        { num: 27, selected: false },
        { num: 28, selected: false },
        { num: 30, selected: false },
        { num: 35, selected: false },
        { num: 40, selected: false },
        { num: 45, selected: false },
        { num: 60, selected: false },
        { num: 75, selected: false },
        { num: 90, selected: false }
      ],
      test: ""
    };
  },
  methods: {
    select(i) {
      this.dayList[i].selected = !this.dayList[i].selected;
      this.$emit(
        "automationChildData",
        "invoiceOverdueReminder",
        this.selectedDates
      );
    },
    setDatesFromParent(d) {
      for (let day of d.days) {
        let updateThis = this.dayList.find(x => x.num == day.num);
        updateThis.selected = true;
      }
    }
  },

  computed: {
    selectedDates() {
      return this.dayList.filter(function(day) {
        return day.selected == true;
      });
    }
  }
};
</script>

<style scoped>
ul {
  list-style-type: none;
  margin: auto;
}

.selected {
  border-radius: 50%;
  width: 6px;
  padding: 6px;

  background: #fff;
  border: 2px solid #666;
  color: #666;
  text-align: center;
}

.fit {
  width: 400px;
}

@media only screen and (max-width: 600px) {
  .fit {
    width: 100%;
  }

  .selected {
    padding: 0;
  }
}

.days {
  padding: 10px 0;
  background: #eee;
  margin: 0;
}

.days li {
  list-style-type: none;
  display: inline-block;
  width: 13.6%;
  text-align: center;
  margin-bottom: 5px;
  font-size: 12px;
  color: #777;
}
</style>
