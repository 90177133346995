<template>
  <div>
    <div class="mt-5 circle-loader load-complete">
      <div v-show="true" class="checkmark draw"></div>
    </div>
    <div class="color-blue-grey-700">
      Xero file connected 😊. Review your connections or contact list. ph - tell
      the customer what to do next??? Continue button
    </div>
  </div>
</template>

<script>
export default {
  name: "XeroRedirect",
  data() {
    return {
      url_params: "",
      code: "",
      response: "",
      xerodata: ""
    };
  },
  components: {},
  props: {},
  methods: {
    async getXeroToken() {
      this.axios
        .get(
          process.env.VUE_APP_BASE_API_URL +
            "auth/" +
            this.$store.getters.getUserGUID +
            "/connect/xero/firstconnection?code=" +
            this.code,
          {
            withCredentials: true
          }
        )
        .then(response => {
          this.response = response;
        });
    }
  },

  created() {
    //if url params contains code then ... else ...

    this.url_params = this.$route.query;
    if (this.$route.query.code) {
      this.code = this.$route.query.code;
      this.getXeroToken();
    } else {
      this.code = "no code ....";
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.circle-loader {
  margin-bottom: 3.5em;
  border: 3px solid rgba(0, 0, 0, 0.2);
  border-left-color: #5cb85c;
  animation: loader-spin 1.2s infinite linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: 7em;
  height: 7em;
}
.load-complete {
  -webkit-animation: none;
  animation: none;
  border-color: #5cb85c;
  transition: border 500ms ease-out;
}
.checkmark {
}
.checkmark.draw:after {
  animation-duration: 800ms;
  animation-timing-function: ease;
  animation-name: checkmark;
  transform: scaleX(-1) rotate(135deg);
}
.checkmark:after {
  opacity: 1;
  height: 3.5em;
  width: 1.75em;
  transform-origin: left top;
  border-right: 3px solid #5cb85c;
  border-top: 3px solid #5cb85c;
  content: "";
  left: 1.75em;
  top: 3.5em;
  position: absolute;
}
@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 1.75em;
    opacity: 1;
  }
  40% {
    height: 3.5em;
    width: 1.75em;
    opacity: 1;
  }
  100% {
    height: 3.5em;
    width: 1.75em;
    opacity: 1;
  }
}
</style>
