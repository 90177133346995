<template>
  <div
    style="padding:30px; min-width: 100%; "
    class="color-blue-grey-600 bg-grey-50 w-sm-310"
  >
    <div style="font-size:32px;" class="my-3 color-blue-grey-700 w-sm-315">
      <div class="text-left">
        <i class="fas fa-cogs color-light-blue-700"></i> Automations
      </div>
    </div>

    <div style="max-width:800px; margin-right:auto">
      <div class="d-block w80" style="width: fit-content;">
        <b-button
          @click="saveAutomation"
          variant="outline-secondary"
          class="mt-1 mb-4 mr-auto"
          ><i class="fas fa-save color-teal-a700 "></i> Save
          automations</b-button
        >
      </div>

      <!-- invoice created thankyou -->

      <b-row>
        <div
          class="card px-4 py-4 w100"
          v-bind:class="{
            borderGreen: automationConfig.xero.invoiceCreated.active,
            borderClear: !automationConfig.xero.invoiceCreated.active
          }"
        >
          <div style="display:inherit">
            <b-form-checkbox
              switch
              size="lg"
              v-model="automationConfig.xero.invoiceCreated.active"
            >
              <span class="text-shrink">Invoice Created</span>
            </b-form-checkbox>

            <b-icon-question-circle
              class="icon-size"
              id="tooltip-created-question"
            ></b-icon-question-circle>
            <b-tooltip target="tooltip-created-question"
              >Send out an SMS to the customer when an invoice is
              created</b-tooltip
            >

            <span
              v-if="automationConfig.xero.invoiceCreated.active"
              class="active"
              ><i class="fa-solid fa-gear fa-spin mt-1"></i
              ><span class="display-none mt-1">Active</span></span
            >
            <span v-else class="inactive mt-1 mt-1"
              ><span class="display-none">Inactive</span></span
            >
          </div>
        </div></b-row
      >

      <!-- invoice paid thankyou -->

      <b-row>
        <div
          class="card px-4 py-4 w100"
          v-bind:class="{
            borderGreen: automationConfig.xero.invoicePaid.active,
            borderClear: !automationConfig.xero.invoicePaid.active
          }"
        >
          <div style="display:inherit">
            <b-form-checkbox
              switch
              size="lg"
              v-model="automationConfig.xero.invoicePaid.active"
            >
              <span class="text-shrink">Payment received thank you</span>
            </b-form-checkbox>

            <b-icon-question-circle
              class="icon-size mt-1"
              id="tooltip-paid-question"
            ></b-icon-question-circle>
            <b-tooltip target="tooltip-paid-question"
              >Send out an SMS thanking the customer for payment.</b-tooltip
            >

            <span v-if="automationConfig.xero.invoicePaid.active" class="active"
              ><i class="fa-solid fa-gear fa-spin mt-1"></i
              ><span class="display-none">Active</span></span
            >
            <span v-else class="inactive mt-1"
              ><span class="display-none">Inactive</span></span
            >
          </div>
        </div></b-row
      >

      <!-- invoice pay reminder on due date -->

      <b-row>
        <div
          class="card px-4 py-4 w100"
          v-bind:class="{
            borderGreen: automationConfig.xero.invoiceDueReminder.active,
            borderClear: !automationConfig.xero.invoiceDueReminder.active
          }"
        >
          <div style="display:inherit">
            <b-form-checkbox
              switch
              size="lg"
              v-model="automationConfig.xero.invoiceDueReminder.active"
            >
              <span class="text-shrink">Invoice payment due reminder</span>
            </b-form-checkbox>

            <b-icon-question-circle
              class="icon-size mt-1"
              id="tooltip-dayof-question"
            ></b-icon-question-circle>
            <b-tooltip target="tooltip-dayof-question"
              >Send out an unpaid invoice reminder on the due data.</b-tooltip
            >

            <span
              v-if="automationConfig.xero.invoiceDueReminder.active"
              class="active"
              ><i class="fa-solid fa-gear fa-spin"></i
              ><span class="display-none">Active</span></span
            >
            <span v-else class="inactive"
              ><span class="display-none">Inactive</span></span
            >
          </div>
        </div></b-row
      >
      <b-row>
        <!-- invoice upcoming reminder -->
        <div
          class="card px-4 py-4 w100"
          v-bind:class="{
            borderGreen: automationConfig.xero.invoiceUpcomingReminder.active,
            borderClear: !automationConfig.xero.invoiceUpcomingReminder.active
          }"
        >
          <div style="display:inherit">
            <b-form-checkbox
              switch
              size="lg"
              v-model="automationConfig.xero.invoiceUpcomingReminder.active"
            >
              <span class="text-shrink"
                >Invoice upcoming payment due reminder</span
              >
            </b-form-checkbox>
            <b-icon-question-circle
              id="tooltip-upcoming-question"
              class="icon-size "
            ></b-icon-question-circle>
            <b-tooltip target="tooltip-upcoming-question"
              >Select the days
              <span style="text-decoration: underline;">before</span> an invoice
              due date to send the reminder, e.g, select 2 to send a reminder 2
              days <span style="text-decoration: underline;">before</span> the
              invoice is due.</b-tooltip
            >

            <span
              v-if="automationConfig.xero.invoiceUpcomingReminder.active"
              class="active"
              ><i class="fa-solid fa-gear fa-spin"></i
              ><span class="display-none">Active</span></span
            >
            <span v-else class="inactive"
              ><span class="display-none">Inactive</span></span
            >
          </div>
          <div
            class=" my-3"
            :class="{
              off: !this.automationConfig.xero.invoiceUpcomingReminder.active
            }"
          >
            Send a payment reminder
            <strong>{{ this.upcomingDays }}</strong> day(s) before an invoice is
            due
            <uc_calendar
              ref="upcomingCalender"
              @automationChildData="storeChildData"
            ></uc_calendar>
          </div>
        </div>
      </b-row>
      <b-row>
        <!-- invoice overdue reminder -->
        <div
          class="card px-4 py-4 w100"
          v-bind:class="{
            borderGreen: automationConfig.xero.invoiceOverdueReminder.active,
            borderClear: !automationConfig.xero.invoiceOverdueReminder.active
          }"
        >
          <div style="display:inherit">
            <b-form-checkbox
              switch
              size="lg"
              v-model="automationConfig.xero.invoiceOverdueReminder.active"
            >
              <span class="text-shrink">Invoice payment overdue reminder</span>
            </b-form-checkbox>
            <b-icon-question-circle
              id="tooltip-overdue-question"
              class="icon-size "
            ></b-icon-question-circle>
            <b-tooltip target="tooltip-overdue-question"
              >Select the days
              <span style="text-decoration: underline;">after</span> an invoice
              due date to send the reminder, e.g, select 2 to send an overdue
              reminder 2 days
              <span style="text-decoration: underline;">after</span> the invoice
              is due.</b-tooltip
            >
            <span
              v-if="automationConfig.xero.invoiceOverdueReminder.active"
              class="active"
              ><i class="fa-solid fa-gear fa-spin"></i
              ><span class="display-none">Active</span></span
            >
            <span v-else class="inactive"
              ><span class="display-none">Inactive</span></span
            >
          </div>
          <div
            class=" my-3"
            :class="{
              off: !this.automationConfig.xero.invoiceOverdueReminder.active
            }"
          >
            Send a payment reminder
            <strong>{{ this.overdueDays }}</strong> day(s) after an invoice is
            due
            <od_calendar
              ref="overdueCalender"
              :off="!this.automationConfig.xero.invoiceOverdueReminder.active"
              @automationChildData="storeChildData"
            ></od_calendar>
          </div>
        </div>
      </b-row>

      <!-- welcome message -->
      <b-row>
        <div
          class="card px-4 py-4 w100"
          v-bind:class="{
            borderGreen: automationConfig.xero.welcomeMessage.active,
            borderClear: !automationConfig.xero.welcomeMessage.active
          }"
        >
          <div style="display:inherit">
            <b-form-checkbox
              switch
              size="lg"
              v-model="automationConfig.xero.welcomeMessage.active"
            >
              <span class="text-shrink">Welcome message to new customers</span>
            </b-form-checkbox>

            <b-icon-question-circle
              class="icon-size "
              id="tooltip-welcome-question"
            ></b-icon-question-circle>
            <b-tooltip target="tooltip-welcome-question"
              >Send a welcome SMS to new customers</b-tooltip
            >

            <span
              v-if="automationConfig.xero.welcomeMessage.active"
              class="active"
              ><i class="fa-solid fa-gear fa-spin"></i
              ><span class="display-none">Active</span></span
            >
            <span v-else class="inactive mt-1"
              ><span class="display-none">Inactive</span></span
            >
          </div>
        </div></b-row
      >

      <!-- supplier paid -->
      <b-row>
        <div
          class="card px-4 py-4 w100"
          v-bind:class="{
            borderGreen: automationConfig.xero.accountPaid.active,
            borderClear: !automationConfig.xero.accountPaid.active
          }"
        >
          <div style="display:inherit">
            <b-form-checkbox
              switch
              size="lg"
              v-model="automationConfig.xero.accountPaid.active"
            >
              <span class="text-shrink">Supplier invoice paid</span>
            </b-form-checkbox>

            <b-icon-question-circle
              class="icon-size"
              id="tooltip-supplierpaid-question"
            ></b-icon-question-circle>
            <b-tooltip target="tooltip-supplierpaid-question"
              >Notify a supplier when you have paid their invoice</b-tooltip
            >

            <span v-if="automationConfig.xero.accountPaid.active" class="active"
              ><i class="fa-solid fa-gear fa-spin"></i
              ><span class="display-none">Active</span></span
            >
            <span v-else class="inactive"
              ><span class="display-none">Inactive</span></span
            >
          </div>
        </div></b-row
      >
      <div class="d-block" style="width: fit-content;">
        <b-button
          @click="saveAutomation"
          variant="outline-secondary"
          class="mt-1 mb-4 mr-auto"
          ><i class="fas fa-save color-teal-a700 "></i> Save
          automations</b-button
        >
      </div>
    </div>

    <br />
    {{ this.b }}
  </div>
</template>

<script>
import od_calendar from "../panel/automationComponents/overduePaymentCalender";
import uc_calendar from "../panel/automationComponents/upcomingPaymentCalender";

export default {
  name: "automations",
  /*
  beforeRouteLeave(to, from, next) {
    /*this.$dialog
      .confirm("Do you want to proceed?")
      .then(function() {
        next();
      })
      .catch(function() {
        next(false);
      });
  },
  */
  components: {
    od_calendar,
    uc_calendar
  },
  data() {
    return {
      configModified: false,
      automationConfig: {
        xero: {
          invoiceCreated: { active: false },
          invoicePaid: { active: false },
          invoiceDueReminder: { active: false, days: [] },
          invoiceOverdueReminder: {
            active: false,
            days: [
              { num: 3, selected: true },
              { num: 10, selected: true },
              { num: 21, selected: true }
            ]
          },
          invoiceUpcomingReminder: { active: false, days: [] },
          welcomeMessage: { active: false },
          invoicePaidThankyou: { active: false },
          accountPaid: { active: false }
        },
        myob: {}
      },
      resp: ""
    };
  },
  methods: {
    testAutomation() {
      this.axios.get(
        process.env.VUE_APP_BASE_API_URL +
          "auth/" +
          this.$store.getters.getUserGUID +
          "/automations",
        {
          withCredentials: true
        }
      );
    },
    saveAutomation() {
      this.axios
        .post(
          process.env.VUE_APP_BASE_API_URL +
            "auth/" +
            this.$store.getters.getUserGUID +
            "/automations",
          { config: this.automationConfig },
          {
            withCredentials: true
          }
        )
        .then(this.showToast());
    },

    storeChildData(type, data) {
      this.automationConfig.xero[type].active = true;
      this.automationConfig.xero[type].days = data;
    },

    showToast() {
      this.$root.$bvToast.toast("Automations saved", {
        title: "Saved",
        variant: "success",
        toaster: "b-toaster-top-full",
        autoHideDelay: 2000
      });
    }
  },
  computed: {
    upcomingDays() {
      let o = this.automationConfig.xero.invoiceUpcomingReminder.days;

      let a = o
        .map(a => a.num)
        .sort(function(a, b) {
          return a - b;
        })
        .toString();

      if (a.split(",").length - 1 > 0) {
        return (
          a.substring(0, a.lastIndexOf(",")) +
          " and " +
          a.substring(a.lastIndexOf(",") + 1)
        );
      } else {
        return a;
      }
    },
    overdueDays() {
      let o = this.automationConfig.xero.invoiceOverdueReminder.days;

      let a = o
        .map(a => a.num)
        .sort(function(a, b) {
          return a - b;
        })
        .toString();

      if (a.split(",").length - 1 > 0) {
        return (
          a.substring(0, a.lastIndexOf(",")) +
          " and " +
          a.substring(a.lastIndexOf(",") + 1)
        );
      } else {
        return a;
      }
    }
  },
  mounted() {
    this.$nextTick(function() {
      this.axios
        .get(
          process.env.VUE_APP_BASE_API_URL +
            "auth/" +
            this.$store.getters.getUserGUID +
            "/automations",
          {
            withCredentials: true
          }
        )
        .then(response => {
          if (response.status == 200) {
            this.automationConfig = response.data;

            this.$refs.upcomingCalender.setDatesFromParent(
              response.data.xero.invoiceUpcomingReminder
            );
            this.$refs.overdueCalender.setDatesFromParent(
              response.data.xero.invoiceOverdueReminder
            );
          }
        });
    });
  }
};
</script>

<style scoped>
.row {
  margin-left: 0px;
  padding-bottom: 12px;
  text-align: left;
}

.w100 {
  width: 100%;
}

.icon-size {
  font-size: 1.5rem;
  padding: 2px;
  color: grey;
  margin-top: 2px;
  margin-left: auto;
}

.borderGreen {
  border: 1px solid green !important;
}

.borderClear {
  border: 1px solid #f9f6f9;
}

.w80 {
  width: 80%;
}

.off {
  opacity: 0.2;
}

.active {
  color: green !important;
  padding-top: 1px;
  padding-left: 4px;
}

.inactive {
  color: grey;
  text-align: right;
  padding-top: 1px;
}

@media only screen and (max-width: 450px) {
  .display-none {
    display: none;
  }
  .text-shrink {
    font-size: 16px;
  }

  .selected {
    padding: 0;
  }

  .w100 {
    width: 350px;
  }

  .w80 {
    width: 100%;
  }

  .w-sm-310 {
    width: 310px;
  }
}
</style>
