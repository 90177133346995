import { render, staticRenderFns } from "./TopUp2.vue?vue&type=template&id=40bd6503&scoped=true"
import script from "./TopUp2.vue?vue&type=script&lang=js"
export * from "./TopUp2.vue?vue&type=script&lang=js"
import style0 from "./TopUp2.vue?vue&type=style&index=0&id=40bd6503&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40bd6503",
  null
  
)

export default component.exports