var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"color-blue-grey-600 bg-grey-50 w-sm-310",staticStyle:{"padding":"30px","min-width":"100%"}},[_vm._m(0),_c('div',{staticStyle:{"max-width":"800px","margin-right":"auto"}},[_c('div',{staticClass:"d-block w80",staticStyle:{"width":"fit-content"}},[_c('b-button',{staticClass:"mt-1 mb-4 mr-auto",attrs:{"variant":"outline-secondary"},on:{"click":_vm.saveAutomation}},[_c('i',{staticClass:"fas fa-save color-teal-a700"}),_vm._v(" Save automations")])],1),_c('b-row',[_c('div',{staticClass:"card px-4 py-4 w100",class:{
          borderGreen: _vm.automationConfig.xero.invoiceCreated.active,
          borderClear: !_vm.automationConfig.xero.invoiceCreated.active
        }},[_c('div',{staticStyle:{"display":"inherit"}},[_c('b-form-checkbox',{attrs:{"switch":"","size":"lg"},model:{value:(_vm.automationConfig.xero.invoiceCreated.active),callback:function ($$v) {_vm.$set(_vm.automationConfig.xero.invoiceCreated, "active", $$v)},expression:"automationConfig.xero.invoiceCreated.active"}},[_c('span',{staticClass:"text-shrink"},[_vm._v("Invoice Created")])]),_c('b-icon-question-circle',{staticClass:"icon-size",attrs:{"id":"tooltip-created-question"}}),_c('b-tooltip',{attrs:{"target":"tooltip-created-question"}},[_vm._v("Send out an SMS to the customer when an invoice is created")]),(_vm.automationConfig.xero.invoiceCreated.active)?_c('span',{staticClass:"active"},[_c('i',{staticClass:"fa-solid fa-gear fa-spin mt-1"}),_c('span',{staticClass:"display-none mt-1"},[_vm._v("Active")])]):_c('span',{staticClass:"inactive mt-1 mt-1"},[_c('span',{staticClass:"display-none"},[_vm._v("Inactive")])])],1)])]),_c('b-row',[_c('div',{staticClass:"card px-4 py-4 w100",class:{
          borderGreen: _vm.automationConfig.xero.invoicePaid.active,
          borderClear: !_vm.automationConfig.xero.invoicePaid.active
        }},[_c('div',{staticStyle:{"display":"inherit"}},[_c('b-form-checkbox',{attrs:{"switch":"","size":"lg"},model:{value:(_vm.automationConfig.xero.invoicePaid.active),callback:function ($$v) {_vm.$set(_vm.automationConfig.xero.invoicePaid, "active", $$v)},expression:"automationConfig.xero.invoicePaid.active"}},[_c('span',{staticClass:"text-shrink"},[_vm._v("Payment received thank you")])]),_c('b-icon-question-circle',{staticClass:"icon-size mt-1",attrs:{"id":"tooltip-paid-question"}}),_c('b-tooltip',{attrs:{"target":"tooltip-paid-question"}},[_vm._v("Send out an SMS thanking the customer for payment.")]),(_vm.automationConfig.xero.invoicePaid.active)?_c('span',{staticClass:"active"},[_c('i',{staticClass:"fa-solid fa-gear fa-spin mt-1"}),_c('span',{staticClass:"display-none"},[_vm._v("Active")])]):_c('span',{staticClass:"inactive mt-1"},[_c('span',{staticClass:"display-none"},[_vm._v("Inactive")])])],1)])]),_c('b-row',[_c('div',{staticClass:"card px-4 py-4 w100",class:{
          borderGreen: _vm.automationConfig.xero.invoiceDueReminder.active,
          borderClear: !_vm.automationConfig.xero.invoiceDueReminder.active
        }},[_c('div',{staticStyle:{"display":"inherit"}},[_c('b-form-checkbox',{attrs:{"switch":"","size":"lg"},model:{value:(_vm.automationConfig.xero.invoiceDueReminder.active),callback:function ($$v) {_vm.$set(_vm.automationConfig.xero.invoiceDueReminder, "active", $$v)},expression:"automationConfig.xero.invoiceDueReminder.active"}},[_c('span',{staticClass:"text-shrink"},[_vm._v("Invoice payment due reminder")])]),_c('b-icon-question-circle',{staticClass:"icon-size mt-1",attrs:{"id":"tooltip-dayof-question"}}),_c('b-tooltip',{attrs:{"target":"tooltip-dayof-question"}},[_vm._v("Send out an unpaid invoice reminder on the due data.")]),(_vm.automationConfig.xero.invoiceDueReminder.active)?_c('span',{staticClass:"active"},[_c('i',{staticClass:"fa-solid fa-gear fa-spin"}),_c('span',{staticClass:"display-none"},[_vm._v("Active")])]):_c('span',{staticClass:"inactive"},[_c('span',{staticClass:"display-none"},[_vm._v("Inactive")])])],1)])]),_c('b-row',[_c('div',{staticClass:"card px-4 py-4 w100",class:{
          borderGreen: _vm.automationConfig.xero.invoiceUpcomingReminder.active,
          borderClear: !_vm.automationConfig.xero.invoiceUpcomingReminder.active
        }},[_c('div',{staticStyle:{"display":"inherit"}},[_c('b-form-checkbox',{attrs:{"switch":"","size":"lg"},model:{value:(_vm.automationConfig.xero.invoiceUpcomingReminder.active),callback:function ($$v) {_vm.$set(_vm.automationConfig.xero.invoiceUpcomingReminder, "active", $$v)},expression:"automationConfig.xero.invoiceUpcomingReminder.active"}},[_c('span',{staticClass:"text-shrink"},[_vm._v("Invoice upcoming payment due reminder")])]),_c('b-icon-question-circle',{staticClass:"icon-size",attrs:{"id":"tooltip-upcoming-question"}}),_c('b-tooltip',{attrs:{"target":"tooltip-upcoming-question"}},[_vm._v("Select the days "),_c('span',{staticStyle:{"text-decoration":"underline"}},[_vm._v("before")]),_vm._v(" an invoice due date to send the reminder, e.g, select 2 to send a reminder 2 days "),_c('span',{staticStyle:{"text-decoration":"underline"}},[_vm._v("before")]),_vm._v(" the invoice is due.")]),(_vm.automationConfig.xero.invoiceUpcomingReminder.active)?_c('span',{staticClass:"active"},[_c('i',{staticClass:"fa-solid fa-gear fa-spin"}),_c('span',{staticClass:"display-none"},[_vm._v("Active")])]):_c('span',{staticClass:"inactive"},[_c('span',{staticClass:"display-none"},[_vm._v("Inactive")])])],1),_c('div',{staticClass:"my-3",class:{
            off: !this.automationConfig.xero.invoiceUpcomingReminder.active
          }},[_vm._v(" Send a payment reminder "),_c('strong',[_vm._v(_vm._s(this.upcomingDays))]),_vm._v(" day(s) before an invoice is due "),_c('uc_calendar',{ref:"upcomingCalender",on:{"automationChildData":_vm.storeChildData}})],1)])]),_c('b-row',[_c('div',{staticClass:"card px-4 py-4 w100",class:{
          borderGreen: _vm.automationConfig.xero.invoiceOverdueReminder.active,
          borderClear: !_vm.automationConfig.xero.invoiceOverdueReminder.active
        }},[_c('div',{staticStyle:{"display":"inherit"}},[_c('b-form-checkbox',{attrs:{"switch":"","size":"lg"},model:{value:(_vm.automationConfig.xero.invoiceOverdueReminder.active),callback:function ($$v) {_vm.$set(_vm.automationConfig.xero.invoiceOverdueReminder, "active", $$v)},expression:"automationConfig.xero.invoiceOverdueReminder.active"}},[_c('span',{staticClass:"text-shrink"},[_vm._v("Invoice payment overdue reminder")])]),_c('b-icon-question-circle',{staticClass:"icon-size",attrs:{"id":"tooltip-overdue-question"}}),_c('b-tooltip',{attrs:{"target":"tooltip-overdue-question"}},[_vm._v("Select the days "),_c('span',{staticStyle:{"text-decoration":"underline"}},[_vm._v("after")]),_vm._v(" an invoice due date to send the reminder, e.g, select 2 to send an overdue reminder 2 days "),_c('span',{staticStyle:{"text-decoration":"underline"}},[_vm._v("after")]),_vm._v(" the invoice is due.")]),(_vm.automationConfig.xero.invoiceOverdueReminder.active)?_c('span',{staticClass:"active"},[_c('i',{staticClass:"fa-solid fa-gear fa-spin"}),_c('span',{staticClass:"display-none"},[_vm._v("Active")])]):_c('span',{staticClass:"inactive"},[_c('span',{staticClass:"display-none"},[_vm._v("Inactive")])])],1),_c('div',{staticClass:"my-3",class:{
            off: !this.automationConfig.xero.invoiceOverdueReminder.active
          }},[_vm._v(" Send a payment reminder "),_c('strong',[_vm._v(_vm._s(this.overdueDays))]),_vm._v(" day(s) after an invoice is due "),_c('od_calendar',{ref:"overdueCalender",attrs:{"off":!this.automationConfig.xero.invoiceOverdueReminder.active},on:{"automationChildData":_vm.storeChildData}})],1)])]),_c('b-row',[_c('div',{staticClass:"card px-4 py-4 w100",class:{
          borderGreen: _vm.automationConfig.xero.welcomeMessage.active,
          borderClear: !_vm.automationConfig.xero.welcomeMessage.active
        }},[_c('div',{staticStyle:{"display":"inherit"}},[_c('b-form-checkbox',{attrs:{"switch":"","size":"lg"},model:{value:(_vm.automationConfig.xero.welcomeMessage.active),callback:function ($$v) {_vm.$set(_vm.automationConfig.xero.welcomeMessage, "active", $$v)},expression:"automationConfig.xero.welcomeMessage.active"}},[_c('span',{staticClass:"text-shrink"},[_vm._v("Welcome message to new customers")])]),_c('b-icon-question-circle',{staticClass:"icon-size",attrs:{"id":"tooltip-welcome-question"}}),_c('b-tooltip',{attrs:{"target":"tooltip-welcome-question"}},[_vm._v("Send a welcome SMS to new customers")]),(_vm.automationConfig.xero.welcomeMessage.active)?_c('span',{staticClass:"active"},[_c('i',{staticClass:"fa-solid fa-gear fa-spin"}),_c('span',{staticClass:"display-none"},[_vm._v("Active")])]):_c('span',{staticClass:"inactive mt-1"},[_c('span',{staticClass:"display-none"},[_vm._v("Inactive")])])],1)])]),_c('b-row',[_c('div',{staticClass:"card px-4 py-4 w100",class:{
          borderGreen: _vm.automationConfig.xero.accountPaid.active,
          borderClear: !_vm.automationConfig.xero.accountPaid.active
        }},[_c('div',{staticStyle:{"display":"inherit"}},[_c('b-form-checkbox',{attrs:{"switch":"","size":"lg"},model:{value:(_vm.automationConfig.xero.accountPaid.active),callback:function ($$v) {_vm.$set(_vm.automationConfig.xero.accountPaid, "active", $$v)},expression:"automationConfig.xero.accountPaid.active"}},[_c('span',{staticClass:"text-shrink"},[_vm._v("Supplier invoice paid")])]),_c('b-icon-question-circle',{staticClass:"icon-size",attrs:{"id":"tooltip-supplierpaid-question"}}),_c('b-tooltip',{attrs:{"target":"tooltip-supplierpaid-question"}},[_vm._v("Notify a supplier when you have paid their invoice")]),(_vm.automationConfig.xero.accountPaid.active)?_c('span',{staticClass:"active"},[_c('i',{staticClass:"fa-solid fa-gear fa-spin"}),_c('span',{staticClass:"display-none"},[_vm._v("Active")])]):_c('span',{staticClass:"inactive"},[_c('span',{staticClass:"display-none"},[_vm._v("Inactive")])])],1)])]),_c('div',{staticClass:"d-block",staticStyle:{"width":"fit-content"}},[_c('b-button',{staticClass:"mt-1 mb-4 mr-auto",attrs:{"variant":"outline-secondary"},on:{"click":_vm.saveAutomation}},[_c('i',{staticClass:"fas fa-save color-teal-a700"}),_vm._v(" Save automations")])],1)],1),_c('br'),_vm._v(" "+_vm._s(this.b)+" ")])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-3 color-blue-grey-700 w-sm-315",staticStyle:{"font-size":"32px"}},[_c('div',{staticClass:"text-left"},[_c('i',{staticClass:"fas fa-cogs color-light-blue-700"}),_vm._v(" Automations ")])])
}]

export { render, staticRenderFns }