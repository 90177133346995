<template>
  <div v-show="orgs">
    <div class="card">
      <b-overlay :show="this.showloader">
        <div class="d-flex">
          <h6 class="text-left color-grey-900 m-3">
            <i class="fa-regular fa-thumbs-up color-light-blue-700"></i>
            Connections
          </h6>
          <div class="my-2 py-1">
            <b-button
              variant="outline-secondary"
              size="sm"
              class="color-cyan-700"
              pill
              @click="updateOrgStatus()"
              ><i class="fa-solid fa-arrows-rotate color-cyan-700"></i> Refesh
              status</b-button
            >
          </div>
        </div>

        <div style="font-size:14px;">
          <div v-for="org in sortedOrgs" :key="org.id" class="my-1">
            <b-row cols="1" align-h="start" cols-md="10">
              <b-col md="1">
                <img
                  :src="imageSrc.xero"
                  alt="no image"
                  style="height:80px; "
                  class=" my-auto"
              /></b-col>
              <b-col
                md="1"
                class="mx-md-1 px-4 my-auto"
                style="margin-top: auto;
margin-bottom: auto;"
              >
                <i
                  v-show="activeOrg(org)"
                  class="fa-regular fa-circle-check color-green-a400 fa-2x m-1"
                ></i>
                <i
                  v-show="!activeOrg(org)"
                  class="fa-regular fa-circle-xmark color-red-a400 fa-2x m-1"
                ></i>
              </b-col>
              <b-col class="mb-3" md="6"
                ><div
                  class="color-grey-800 mt-3 text-md-left "
                  style="font-weight:600"
                >
                  {{ org.xeroTenantName }}
                </div>
                <div class="text-md-left">
                  linked: {{ date(org.dt) }}
                </div></b-col
              >

              <b-col
                md="2"
                style="margin-top: auto;
margin-bottom: auto;"
              >
                <b-button
                  pill
                  style="width: max-content;"
                  variant="outline-danger"
                  size="sm"
                  :disabled="
                    orgIsDisconnected(
                      org.disconnectedByClient,
                      org.disconnectedByXero
                    )
                  "
                  @click="disconnectXeroOrg(org.xeroID)"
                  ><i class="fa-regular fa-circle-xmark"></i>
                  Disconnect</b-button
                ></b-col
              ></b-row
            >
            <hr />
          </div></div
      ></b-overlay>
    </div>
  </div>
</template>

<script>
export default {
  name: "Connections",
  components: {},
  data() {
    return {
      text: "",
      showloader: true,
      orgs: [],
      imageSrc: {
        xero: "https://omnimodaStorage.b-cdn.net/Xero%20-%20Logo%20-%20Blue.png"
      }
    };
  },
  methods: {
    date(dt) {
      let d = new Date(dt);

      return d.toLocaleDateString();
    },
    orgIsDisconnected(a, b) {
      return a ? a : b;
    },
    getOrgsFromDB() {
      this.showloader = true;
      this.axios
        .get(
          process.env.VUE_APP_BASE_API_URL +
            "auth/" +
            this.$store.getters.getUserGUID +
            "/connect/connections",
          {
            withCredentials: true
          }
        )
        .then(
          response => ((this.orgs = response.data), (this.showloader = false))
        );
    },
    disconnectXeroOrg(id) {
      this.axios
        .get(
          process.env.VUE_APP_BASE_API_URL +
            "auth/" +
            this.$store.getters.getUserGUID +
            "/connect/connection/xero/remove/" +
            id,
          {
            withCredentials: true
          }
        )
        .then(response => {
          this.text = response.data;
          this.getOrgsFromDB();
        });
    },
    activeOrg(o) {
      console.log(!o.disconnectedByXero & !o.disconnectedByClient);
      return !o.disconnectedByXero & !o.disconnectedByClient;
    },
    updateOrgStatus() {
      this.axios
        .get(
          process.env.VUE_APP_BASE_API_URL +
            "auth/" +
            this.$store.getters.getUserGUID +
            "/connect/connection/listOrgsFromXero",
          {
            withCredentials: true
          }
        )
        .then(() => {
          this.getOrgsFromDB();
        });
    }
  },
  computed: {
    sortedOrgs() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.orgs.sort(function(a, b) {
        return new Date(b.dt) - new Date(a.dt);
      });
    }
  },
  mounted() {
    this.getOrgsFromDB();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.button-disconnect {
  background: 0 0;
  border-radius: 3px;
  color: #dc3246;
  font-weight: 700;
  line-height: 1rem;
  min-height: 40px;
  padding: 11px 16px;
  position: relative;
  transition-duration: 0.1s;
  transition-property: color, background;
  transition-timing-function: ease;
  margin-left: auto;
}
.button-disconnect:hover {
  background: rgba(0, 10, 30, 0.05);
  color: #d13044;
}
</style>
