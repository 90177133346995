<template>
  <div>
    <div
      class="mt-5 mx-5"
      style="font-family: 'Open Sans'; font-size:2em; text-align: left; font-weight:700;"
    >
      Groups
    </div>
    <router-link
      :to="{
        name: 'groupNew'
      }"
    >
      <b-button class="my-5" variant="outline-primary"
        ><i class="far fa-plus-square"></i> Create New Group</b-button
      >
    </router-link>
    <div style="width:90%" class="mx-auto">
      <b-table :items="groups" :fields="fields">
        <template #cell(edit)="data">
          <router-link
            :to="{
              name: 'group',
              query: {
                groupID: data.item.groupID,
                groupName: data.item.groupName
              }
            }"
          >
            <b-button><i class="far fa-edit"></i> EDIT GROUP</b-button>
          </router-link>
        </template>

        <template #cell(delete)="data">
          <b-button variant="danger">
            <i class="far fa-times-circle"></i>{{ data.item.index }} DELETE
            GROUP</b-button
          >
        </template>
        <template #cell(datecreated)="data">
          {{ data.item.datecreated.split("T")[0] }}
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "groups",
  data() {
    return {
      text: "",
      groups: "",
      fields: [
        "groupName",
        { key: "datecreated", label: "Date Created" },
        { key: "memberCount", label: "# Members" },
        "edit",
        "delete"
      ]
    };
  },
  methods: {
    getGroups() {
      let userGUID = this.$store.getters.getUserGUID;

      this.axios
        .get(
          process.env.VUE_APP_BASE_API_URL + "auth/" + userGUID + "/groups",
          {
            withCredentials: true
          }
        )
        .then(response => (this.groups = response.data));
    },
    deleteGroup(gid, gn) {
      let that = this;

      let userGUID = this.$store.getters.getUserGUID;
      this.axios
        .delete(
          process.env.VUE_APP_BASE_API_URL +
            "auth/" +
            userGUID +
            "/group/" +
            gid,
          {
            withCredentials: true
          }
        )
        .then(function() {
          let index = that.groups
            .map(function(item) {
              return item.groupID;
            })
            .indexOf(gid);

          that.groups.splice(index, 1);

          that.successToast(gn);
        });
    },
    successToast(gname) {
      this.$root.$bvToast.toast(`Group list ${gname} deleted`, {
        title: "Success",
        variant: "Info",
        toaster: "b-toaster-bottom-right",
        solid: true
      });
    }
  },

  mounted() {
    let userGUID = this.$store.getters.getUserGUID;

    this.axios
      .get(process.env.VUE_APP_BASE_API_URL + "auth/" + userGUID + "/groups", {
        withCredentials: true
      })
      .then(response => (this.groups = response.data));
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
