<template>
  <div class="my-5">
    <div class=" my-5">
      <div class=" py-3" style="max-width: 1200px">
        <h3 class="color-grey-800">Topup your credits.</h3>
        <h6>A one-off boost to your SMS credits. Topups do not expire.</h6>
        <div>
          <b-row cols="1" cols-lg="3" class="mx-auto">
            <div v-for="(price, index) in prices" :key="price.id" class="my-5">
              <b-col class="mx-2">
                <div @click="focused = index">
                  <div
                    :class="[
                      focused == index ? 'pricecard-focused' : 'pricecard'
                    ]"
                    class="p-3"
                  >
                    <b-col
                      ><b-row
                        ><div class="text-left mb-2">
                          {{ price.name }}
                        </div>
                      </b-row>
                      <b-row
                        ><div
                          class="text-left  bg-indigo-100 py-1 px-2 my-1"
                          style="font-size:12px; border-radius:10px;"
                        >
                          {{ price.button1 }}
                        </div></b-row
                      ></b-col
                    >
                    <div style="word-wrap: break-word;" class="my-3">
                      <span style="font-size:xxx-large; font-weight:600"
                        >${{ price.price }}</span
                      >
                    </div>
                    <span class="color-blue-a700 my-3">
                      {{ price.credits }} Credits
                      <i
                        class="fa-regular fa-circle-question"
                        v-b-tooltip.hover
                        title="A one-off boost to your SMS credits. Topups do not expire."
                      ></i
                    ></span>
                    <div
                      class="color-indigo-100 bg-indigo-700 getstarted-button my-3"
                    >
                      <div @click="getStripeSession(price.pricecode)">
                        <div>
                          Click here to add credits
                        </div>
                      </div>
                    </div>
                  </div>
                </div></b-col
              >
            </div>
          </b-row>
          <stripe-checkout
            ref="checkoutRef"
            :pk="this.checkoutID"
            :session-id="this.sessionID"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//this is a hack peice of crap this component. zero forethought went into this. <- the creator.

import { StripeCheckout } from "@vue-stripe/vue-stripe";
export default {
  components: {
    StripeCheckout
  },
  name: "topup",
  props: {
    showHeader: { type: Boolean, default: true },
    hideFooter: { type: Boolean, default: false }
  },
  data() {
    return {
      term: "",
      focused: -1,
      planVal: 0,
      stripeID: "",
      nameState: true,
      emailState: true,
      sessionID: "",
      checkoutID: process.env.VUE_APP_STRIPE_CHECKOUT,
      prices: [
        {
          price: 10,
          pricecode: process.env.VUE_APP_PRICING_10t,

          credits: 800,
          name: "Topup Lite",
          button1: "For low volume users who need a little extra."
        },
        {
          price: 20,
          pricecode: process.env.VUE_APP_PRICING_20t,

          credits: 1800,
          button1: "For users who regularly use more than their monthly amount",
          name: "Topup Everyday"
        },
        {
          price: 45,
          pricecode: process.env.VUE_APP_PRICING_45t,

          credits: 4500,
          button1: "For heavy users or those looking for savings.",
          name: "Topup Large"
        }
      ],

      isMonthly: true
    };
  },
  methods: {
    getStripeSession() {
      let priceCode = this.prices[this.zeroFloor].pricecode;

      let url = process.env.VUE_APP_BASE_API_URL + "stripe";

      this.axios
        .post(
          url,
          {
            price: priceCode,
            mode: "payment"
          },
          { withCredentials: true }
        )
        .then(response => {
          this.resp = response.data;
          this.sessionID = response.data.id;
          this.buyTopUp();
        });
    },

    buyTopUp() {
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout();
    }
  },
  mounted() {},
  computed: {
    zeroFloor() {
      return Math.max(this.focused, 0);
    }
  }
};
</script>

<style scoped>
.pricecard {
  background: white;
  color: rgb(11, 30, 106);
  border-radius: 15px;
  border-style: solid;
  border-color: rgb(197, 202, 233);
  border-width: 1px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  position: relative;
}

.pricecard-focused {
  background: rgb(11, 30, 106) 0%;
  color: white;
  border-radius: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  position: relative;
}

.currentPlan {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  border-radius: 15px;
}

ul {
  list-style-type: none;
  width: 100%;
}

.topUpCard {
  flex-wrap: wrap;

  max-width: 800px;
  margin-left: 20px;
  margin-right: 20px;
}

@media only screen and (max-width: 1000px) {
  .topUpCard {
    flex-wrap: nowrap !important;
  }
}

li {
  width: 100%;
  justify-content: space-between;
  display: flex;
  text-align: left;
}

.getstarted-button {
  border-radius: 8px;
  text-align: center;
  padding: 8px;
  cursor: pointer;
}
</style>
